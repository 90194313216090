import React, { useEffect } from "react";
import { Course } from "../../../interfaces/CourseInterface";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { Teacher } from "../../../interfaces/TeacherInterface";
import Select from "react-select";
import axios from "axios";
import { toast } from "react-toastify";

export default function EditCourseModal(props : { promotion: number, editCourse: Course, setEditCourse: (module: null) => void, refresh: boolean, setRefresh: (refresh: boolean) => void }) {
    const [name, setName] = useState<string>(props.editCourse?.courseName || "");
    const [endDate, setEndDate] = useState<string>(new Date(props.editCourse.endDate).toISOString().split('T')[0]);
    const [coefficient, setCoefficient] = useState<number>(props.editCourse?.coefficient || 0);
    const [teachers, setTeachers] = useState<Teacher[]>(props.editCourse?.teachers || []);
    const [courseteachers, setCourseTeachers] = useState<{value : string, label : string}[]>(props.editCourse.teachers?.map((teacher: Teacher) => ({value : teacher.teacherLogin, label : teacher.firstName + ' ' + teacher.lastName})) || []);
    const auth = useAuth();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SCOLARITE_API_URL}/teacher`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => setTeachers(response.data),
                error => {
                    toast.error("Une erreur est survenue");
                });
    }, [auth.user?.access_token]);


    const handleEditCourse = async (event : any) => {
        event.preventDefault();
        if (name === "" || endDate === "" || coefficient === 0 || courseteachers.length === 0) {
            return;
        }
        const date = new Date(endDate);
        await axios.patch(`${process.env.REACT_APP_SCOLARITE_API_URL}/course/${props.editCourse?.courseId}`, {
            courseName: name,
            endDate: date.toISOString(),
            promotion : props.editCourse.promotion,
            semester : props.editCourse.semester,
            coefficient: coefficient,
        }, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(error => {
                console.error(error);
                toast.error("Une erreur est survenue");
            });

        await Promise.all(props.editCourse.teachers?.map(async (teacher: Teacher) => {
            return axios.post(`${process.env.REACT_APP_SCOLARITE_API_URL}/teacher/removeCourse/${teacher.teacherLogin}`, {
                courseId: props.editCourse.courseId
            }, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
                .then(error => {
                    console.error(error);
                    toast.error("Une erreur est survenue");
                });
        }));

        await Promise.all(courseteachers.map(async (teacher: {value : string, label : string}) => {
            return axios.post(`${process.env.REACT_APP_SCOLARITE_API_URL}/teacher/addCourse/${teacher.value}`, {
                courseId: props.editCourse.courseId
            }, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
                .then(error => {
                    console.error(error);
                    toast.error("Une erreur est survenue");
                });
        }));

        props.setEditCourse(null);
        props.setRefresh(!props.refresh);
    };

    return (
        <Modal show={props.editCourse !== null} onHide={() => props.setEditCourse(null)}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title>Editer le cours</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleEditCourse}>
                    <Form.Label>Nom :</Form.Label>
                    <Form.Control value={name} onChange={(e) => setName(e.target.value)}/>
                    <Form.Label>Date de fin :</Form.Label>
                    <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                    <Form.Label>Coefficient :</Form.Label>
                    <Form.Control type="number" value={coefficient} onChange={(e) => setCoefficient(Number(e.target.value))}/>
                    <Form.Label>Professeurs :</Form.Label>
                    <Select
                        name="colors"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti
                        defaultValue={courseteachers}
                        onChange={(selectedTeachers: any) => setCourseTeachers(selectedTeachers)}
                        options={teachers.map((teacher: Teacher) => ({ value: teacher.teacherLogin, label: teacher.firstName + " " + teacher.lastName }))}
                    />
                    <div className="d-flex justify-content-end m-2">
                        <Button type="submit">Valider</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

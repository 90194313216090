import React, {useContext, useEffect} from 'react';
import { Image } from "react-bootstrap";
import RecapSyllabus from '../components/Common/RecapSyllabus';
import { Syllabus } from '../../../interfaces/SyllabusInterface';
import {SyllabusService} from "../../../services/SyllabusService";
import {useAuth} from "react-oidc-context";
import {ViewContext} from "../../../pages/Syllabus";
import {Group} from "../../../interfaces/AuthentificationInterface";

import download from '../../../assets/icons/download.svg';

export enum SyllabusTableViewState {
    RECAP_VIEW = "recap view",
    FILL_FORM = "fill form",
    CREATE_FORM = "create form"
}

function SyllabusTableView() {
    const auth = useAuth();
    const { role, currentPromo } = useContext(ViewContext);
    let [Syllabuses, setSyllabuses] = React.useState<Syllabus[]>([]);
    const teacherLogin = auth.user?.profile.preferred_username;

    const fetchData = async () => {
        if(role === Group.Teacher &&  teacherLogin !== undefined) {
            await SyllabusService.GetSyllabuses(auth, currentPromo, teacherLogin).then((syllabuses) => {
                setSyllabuses(syllabuses);
            });
        }
        else {
            await SyllabusService.GetSyllabuses(auth, currentPromo).then((syllabuses) => {
                setSyllabuses(syllabuses);
            });
        }
    };
    const handleExportAllSyllabuses = async () => {
        await SyllabusService.ExportAllSyllabuses(auth, currentPromo);
    };
    useEffect(() => {
        fetchData();
    }, [currentPromo]);

    return (
        <>
            <div className="d-flex flex-column justify-content-between align-items-center w-100 text-center my-2">
                <>
                    {Syllabuses.map((syllabus, index) => {
                        return <RecapSyllabus syllabus={syllabus} key={index}></RecapSyllabus>;
                    })
                    }
                    <div
                        className="d-flex flex-row justify-content-end align-items-center bg-primary w-100 border-1 p-1 rounded text-center text-white">
                        Syllabus : {Syllabuses.length}
                    </div>
                </>
            </div>
            {role === Group.MajorLeader && (
                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary m-1" onClick={handleExportAllSyllabuses}>
                        <Image src={download} fluid alt="Exporter tous les syllabus" style={{ width: "30px", height: "30px", filter: "invert(100%)" }}/>
                    </button>
                </div>
            )}
        </>
    );
}

export default SyllabusTableView;

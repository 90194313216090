import React, {useContext, useState} from 'react';
import {SyllabusService} from "../../../../services/SyllabusService";
import {useAuth} from "react-oidc-context";
import { View, ViewContext } from '../../../../pages/Syllabus';
import { CourseService } from '../../../../services/CourseService';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

function SyllabusCreateFormView() {
    const auth = useAuth();
    const textLengthLimit = 20;
    const { currentPromo, setCurrentView } = useContext(ViewContext);
    const [formData, setFormData] = useState({
        courseName: '',
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const course = await CourseService.GetCourseByName(auth, formData.courseName, currentPromo);
            await SyllabusService.CreateSyllabus(auth, course, currentPromo);
            toast.success('Syllabus créé avec succès');
            setCurrentView(View.SyllabusTableView);
        } catch (error: unknown) {
            const _error = error as AxiosError;

            if (_error.response?.status === 400) {
                toast.error('Le cours donné n\'existe pas');
            }
            else {
                toast.error('Erreur lors de la création du syllabus');
            }
        }
    };

    return (
        <div className="h-100 w-100 d-flex flex-column my-2 p-2 justify-content-between white-background rounded bg-light align-items-center">
            <div className="d-flex flex-row justify-content-center align-items-center bg-primary rounded w-100 text-center text-white">
                Formulaire de création de syllabus
            </div>
            <br/>
            <form className="w-100" onSubmit={handleSubmit}>
                <div className="form-group mb-2">
                    <label htmlFor="courseName">Nom du cours</label>
                    <input
                        type="text"
                        className="form-control"
                        id="courseName"
                        name="courseName"
                        value={formData.courseName}
                        onChange={handleChange}
                        maxLength={textLengthLimit}
                    />
                    <div style={{ color: textLengthLimit - formData.courseName.length < 5 ? 'red' : 'gray', fontSize: '10px', marginTop: '4px' }}>
                        {textLengthLimit - formData.courseName.length} / {textLengthLimit}
                    </div>
                </div>
                <br/>
                <button type="submit" className="btn btn-primary">
                    Soumettre
                </button>
            </form>
        </div>
    );
}

export default SyllabusCreateFormView;

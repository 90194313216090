import React from 'react';

function CardComponent(props : {label : string, content : any}) {
    let formattedContent = props.content;
    if (props.content) {
        formattedContent = props.content.replace(/\n/g, '<br />');
    }
    return (
        <div className="card">
            <h5 className="card-header">
                {props.label}
            </h5>
            <div className="card-body">
                <p dangerouslySetInnerHTML={{__html: formattedContent}}></p>
            </div>
        </div>
    );
}

export default CardComponent;


import React from 'react';
import { useAuth } from 'react-oidc-context';
import { NavLink, useLocation } from 'react-router-dom';
import signOutLogo from '../../assets/icons/signOut.svg';
import adminlogo from '../../assets/icons/admin.svg';
import userLogo from '../../assets/icons/user.svg';
import syllabusLogo from '../../assets/icons/SyllabusIcon.svg';
import './Sidebar.css';
import { Group } from '../../interfaces/AuthentificationInterface';

// Images imports
import absenceLogo from '../../assets/icons/absence.svg';
import dashboardLogo from '../../assets/icons/dashboard.svg';
import gradeLogo from '../../assets/icons/grade.svg';
import siglLogo from '../../assets/images/sigl.png';
import helpLogo from '../../assets/icons/help.svg';
import annuaireLogo from '../../assets/icons/annuaire.svg';

// Other imports
import './Sidebar.css';

function Sidebar() {
    const auth = useAuth();
    const location = useLocation();

    // Listing of the available buttons in the sidebar
    // Needed: name, url and logo
    // Use of rel="noopener noreferrer" for security reasons (see https://web.dev/external-anchors-use-rel-noopener/)
    const sidebarElements = [
        { name: 'Dashboard', url: "/", regex:/^\/$/, logo: dashboardLogo, group: [Group.Student, Group.MajorLeader] },
        { name: 'Notes', url: "/notes", regex: /^\/notes(\/?[a-z]*)*$/, logo: gradeLogo, group: [Group.Student, Group.MajorLeader, Group.Teacher] },
        { name: 'Absences', url: "/absences", regex: /^\/absence(\/?[a-z]*)*$/, logo: absenceLogo, group: [Group.Student, Group.MajorLeader] },
        { name: 'Syllabus', url: "/syllabus", regex: /^\/syllabus(\/?[a-z]*)*$/, logo: syllabusLogo, group: [Group.Student, Group.MajorLeader, Group.Teacher] },
        { name: 'Annuaire', url: '/annuaire', regex: /^\/annuaire(\/?[a-z]*)*$/, logo: annuaireLogo, group: [Group.Student, Group.MajorLeader, Group.Teacher] },
        { name: 'Admin', url: "/admin", regex: /^\/admin(\/?[a-z]*)*$/, logo: adminlogo, group: [Group.MajorLeader] },
    ];
    return (
        <div className="sidebar d-flex flex-column flex-shrink-0 bg-light rounded">
            <img src={siglLogo} className='my-2 mx-auto' alt="Logo SIGL" width="50" height="50" />
            <ul className="nav nav-pills rounded-0 nav-flush flex-column mb-auto text-center border-top">
                {sidebarElements.map((element) => {
                    const userGroup = auth.user?.profile.group as Array<Group>;
                    if (element.group.some(item => userGroup.includes(item))) {
                        return (
                            <li key={element.name}>
                                <NavLink to={element.url}
                                    className={({ isActive }) => `nav-link py-3 border-bottom rounded-0 ${isActive ? 'active' : ''}`}
                                    title={element.name}
                                    data-bs-tooltip="tooltip"
                                    data-bs-placement="right"
                                >
                                    <img src={element.logo} alt={element.name} width="30" height="30" style={{ filter: ((element.regex.test(location.pathname)) ? 'invert(100%)' : 'none') }} />
                                </NavLink>
                            </li>
                        );
                    }

                    return <></>;

                }
                )}
            </ul>

            <div className='nav-pills'>
                <NavLink to="/help" className={({ isActive }) => `nav-link border-bottom rounded-0 d-flex flex-column flex-shrink-0 align-items-center border-top ${isActive ? 'active' : ''}`} title='Help'>
                    <img src={helpLogo}
                        width='30'
                        height='30'
                        className='my-3'
                        alt='Aide'
                        title="Aide"
                        data-bs-tooltip="tooltip"
                        data-bs-placement="right"
                        style={{ filter: (("/help" === location.pathname) ? 'invert(100%)' : 'none') }}
                    />
                </NavLink>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" onClick={() => { auth.signoutRedirect(); }} className='d-flex flex-column flex-shrink-0 align-items-center bg-danger'>
                    <img
                        src={signOutLogo}
                        className='my-3'
                        width="30" height="30"
                        alt="Déconnexion"
                        title="Déconnexion"
                        data-bs-tooltip="tooltip"
                        data-bs-placement="right"
                    />
                </a>
                <div className="d-flex align-items-center justify-content-center p-3 border-top bg-light">
                    <img src={userLogo} alt="mdo" width="40" height="40" className="rounded-circle"
                        title={auth.user?.profile.name}
                        data-bs-tooltip="tooltip"
                        data-bs-placement="right" />
                </div>

            </div>
        </div >
    );
}

export default Sidebar;

import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface Props {
    show: boolean;
    onHide: () => void;
    primaryText: string;
    secondaryText: string;
    buttonText: string;
    onClickButton: () => void;
}

const WarningModal: React.FC<Props> = ({
    show,
    onHide,
    primaryText,
    secondaryText,
    buttonText,
    onClickButton,
}) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{primaryText}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{secondaryText}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onClickButton}>
                    {buttonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default WarningModal;

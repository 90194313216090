import goldMedal from '../assets/icons/icons8-gold-medal.svg';
import silverMedal from '../assets/icons/icons8-silver-medal.svg';
import bronzeMedal from '../assets/icons/icons8-bronze-medal.svg';

export const medals = [
    {
        position : 1,
        src : goldMedal
    },
    {
        position : 2,
        src : silverMedal
    },
    {
        position : 3,
        src : bronzeMedal
    }
];

export interface LastNote {
    gradeId: number,
    courseName: string,
    value: number,
    position : number
  }

export interface StudentMean {
  mean: number
}




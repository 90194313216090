import {Button, Form, Modal} from "react-bootstrap";
import React, {useContext} from "react";
import clipboardArrow from '../../../../assets/icons/clipboardArrow.svg';
import edit from '../../../../assets/icons/edit.svg';
import {View, ViewContext} from "../../../../pages/Syllabus";


function ModifOrFillModal(props: { courseId: number, show: boolean, onHide: any }) {
    const { setCurrentView, setSelectedSyllabusCourse } = useContext(ViewContext);

    const handleButtonClick = (view: View) => {
        setSelectedSyllabusCourse(Number(props.courseId));
        setCurrentView(view);
    };

    return (
        <Modal show={props.show}>
            <Form>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>{"Demander une modification ou Remplir un Syllabus"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-center my-2 ms-2">
                        <Button variant="primary" className="d-flex justify-content-center m-2" onClick={() => handleButtonClick(View.SyllabusAskModificationFormView)}>
                            <img src={edit} alt="Modifier" className="icon"
                                style={{width: "50px", height: "50px", filter: "invert(100%)"}}
                            />
                            Demander une modification
                        </Button>
                        <Button variant="primary" className="d-flex justify-content-center m-2" onClick={() => handleButtonClick(View.SyllabusFillFormView)}>
                            <img src={clipboardArrow} alt="Remplir" className="icon"
                                style={{width: "40px", height: "40px", filter: "invert(100%)"}}
                            />
                            Modifier directement
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button variant="secondary" onClick={props.onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}

export default ModifOrFillModal;

import axios from "axios";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useAuth } from "react-oidc-context";
import { Teacher } from "../../interfaces/TeacherInterface";
import {CourseWithMean } from "../../interfaces/CourseInterface";
import TeacherNoteTable from "./components/TeacherNoteTable";
import { toast } from "react-toastify";

function NoteTable(props: { setCourse: (course: CourseWithMean) => void, promotion: number }) {
    const [teacher, setTeacher] = useState<Teacher | null>(null);
    const auth = useAuth();

    useEffect(() => {
        axios.get<Teacher>(`${process.env.REACT_APP_SCOLARITE_API_URL}/teacher/${auth.user?.profile.preferred_username}`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => {
                response.data.courses  = response.data.courses.sort((a, b) => a.courseName.localeCompare(b.courseName));
                setTeacher(response.data);
            }, error => {
                console.error(error);
                toast.error("Une erreur est survenue");
            });
    }, [auth.user?.access_token, auth.user?.profile.preferred_username]);


    if (!teacher)
        return <Spinner animation="border" variant="primary" />;

    return (
        <>
            <TeacherNoteTable setCourse={props.setCourse} promotion={props.promotion} teacher={teacher}/>
            <TeacherNoteTable setCourse={props.setCourse} promotion={props.promotion - 1} teacher={teacher}/>
        </>
    );
}

export default NoteTable;

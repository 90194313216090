import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Spreadsheet, { Matrix, CellBase } from "react-spreadsheet";
import { CourseWithMean } from "../../../../interfaces/CourseInterface";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { Student } from "../../../../interfaces/StudentInterface";
import IndividualTable from "./IndividualTable";
import { toast } from "react-toastify";

function GroupTable(props: { course: CourseWithMean }) {
    const [cells, setCells] = useState<Matrix<CellBase>>([[]]);
    const [individualcells, setIndividualCells] = useState<Matrix<CellBase> | null>(null);
    const [studentsGroups, setStudentsGroups] = useState<{[key : string] : Student[]}>({});
    const auth = useAuth();

    useEffect(() => {
        axios.get<Student[]>(`${process.env.REACT_APP_SCOLARITE_API_URL}/student/students?promotion=${props.course.promotion}`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } }).then(response => {
            response.data.sort((a, b) => {
                if(a.group === b.group)
                    return a.login.localeCompare(b.login);
                return a.group - b.group;
            });
            const groups : {[key : string] : Student[]} = response.data.reduce((groups : {[key : string] : Student[]}, student: Student) => {
                const groupName = student.groupName;
                if (!groups[groupName]) {
                    groups[groupName] = [];
                }
                groups[groupName].push(student);
                return groups;
            }, {});
            setCells(Object.entries(groups).map(([groupName, students]) => {
                return [
                    { value: groupName, readOnly: true }, 
                    { className:"edit-grade", value: "" }, 
                    { value: "❌" }];
            }
            ));
            setStudentsGroups(groups);
        }, error => {
            toast.error("Une erreur est survenue");
        });

    }, [auth.user?.access_token, props]);


    function checkGrades(data: Matrix<CellBase>) {
        const res = data.map(row => {
            row = row.slice(0, 3);
            if (row[1]?.value === undefined || row[1]?.value === "") {
                row[2] = ({ value: "❌" });
            } else {

                row[1].value = row[1].value.replace(",", ".");
                const grade = Number(row[1]?.value);
                if (row[2]) {
                    row[2] = { value: Number.isNaN(grade) || grade < 0 || grade > 20 ? "❌" : "✅" };
                }
            }
            return row;
        }).slice(0, Object.keys(studentsGroups).length);

        setCells(res);

        return data;
    }

    function handleSubmitButton() {
        if (!cells.reduce((acc, row) => {
            return acc && row[2]?.value === "✅";
        }, true)) {
            console.log("invalid grades");
            return;
        }
        const individualcells = cells.map((row, index) => {
            const students = studentsGroups[row[0]?.value];
            const grade = Number(row[1]?.value);
            return students.map(student => {
                return [{ value: student.firstName + " " + student.lastName, readOnly: true, student: student }, { value: student.group, readOnly: true }, { value: grade.toString() }, { value: "✅"}];
            });
        }).flat(1);
        setIndividualCells(individualcells);
    }
    return <>
        {!individualcells && <div className="d-flex flex-column">
            <Spreadsheet
                onChange={(data: Matrix<CellBase>) => {
                    checkGrades(data);
                }}
                data={cells}
                columnLabels={["Nom de groupe", "Note", "Format valide"]}
                className='bg-light text-dark'
            />
            <Button className='m-2' onClick={handleSubmitButton}>
            Continuer
            </Button>
        </div>}
        {individualcells && <IndividualTable course={props.course} cells={individualcells}/>}
    </>;
}

export default GroupTable;

import React from "react";
import Grade from '../../../assets/icons/grade.svg';

function Header() {
    return (
        <div className="page-header d-flex align-items-center p-1 justify-content-between white-background rounded bg-light">
            <h1 className="position-absolute start-50 m-auto">Notes</h1>
            <img src={Grade} alt="Logo notes" className="ms-auto" style={{width : "50px"}} />
        </div>);
}

export default  Header;

import React from "react";
import { Status } from "../../../interfaces/AbsenceInterface";

// The spaces before every status are here for lisibility purposes
function AbsenceFooter(props : { filteredAbsences : any[] | undefined | null }) {

    const statusCount = (status: Status) =>  props.filteredAbsences?.filter((el) => el.status === status).length;

    return (
        <div className="d-flex justify-content-between bg-primary p-3 sticky-footer rounded text-light rounded">
            <div className='d-flex flex-column justify-content-center'>
                <strong className="fs-4">{`Total :`}</strong>
            </div>
            <div className='d-flex flex-column'>
                <strong className="fs-3 text-end">{props.filteredAbsences?.length}</strong>
                <span className="fst-italic fs-6">
                    <span style={{color: 'white'}}>
                        En attente : {statusCount(Status.EN_ATTENTE)}
                    </span> /&nbsp;
                    <span style={{color: 'red'}}>
                    Non justifié : {statusCount(Status.NON_JUSTIFIE)}
                    </span> /&nbsp;
                    <span style={{color: 'green'}}>
                    Excusé : {statusCount(Status.EXCUSE)}
                    </span> /&nbsp;
                    <span style={{color: 'orange'}}>
                        Vu : {statusCount(Status.VU)}
                    </span>
                </span>
            </div>
        </div>);
}

export default AbsenceFooter;

import axios from 'axios';
import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { toast } from 'react-toastify';

function DeclarationAbsenceModal(props : { show: boolean , setShow : any
}	) {
    const [startDate, setStartDate] = useState<Date >(new Date());
    const [endDate, setEndDate] = useState<Date >(new Date());
    const [description, setDescription] = useState<string>('');
    const [file, setFile] = useState<File | null>(null);
    const [isSubmitButtonDisable, SetSubmitButtonIsDisabled] = useState(false);

    const textLengthLimit = 255;

    const auth = useAuth();

    const handleFileChange = (e : React.ChangeEvent<HTMLInputElement>
    ) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile === undefined) return;
        // Check file size (max 1MB)
        if (selectedFile.size > 1000000) {
            alert('Le fichier est trop volumineux (max 1MB)');
            return;
        }
        setFile(selectedFile);
    };

    const handleSubmit = async () => {
        SetSubmitButtonIsDisabled(true);
        const studentLogin = auth.user?.profile.preferred_username;
        if (studentLogin === undefined) return;

        axios.post(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/declaration/student/declareAbsence`,
            {
                studentLogin: studentLogin,
                startDate: startDate.toDateString(),
                endDate: endDate.toDateString(),
                description: description,
                file: file
            },
            { headers: {
                Authorization: `Bearer ${auth.user?.access_token}`,
                'Content-Type': 'multipart/form-data'
            } }).then(response => {
            SetSubmitButtonIsDisabled(false);
            toast.success("Votre absence a bien été déclarée !");
            onHide();
        }, error => {
            if (error.response.data.errors === "Invalid file type.") {
                toast.error("Le fichier selectionné n'est pas supporté. Les fichiers peuvent être des png, jpeg, jpg, pdf ou heic");
            }
            else if (error.response.status === 413 || error.response.data.errors === "File size exceeds 1MB limit.") {
                toast.error("Le fichier selectionné est trop volumineux. Il ne doit pas être superieur à 1MB.");
            }
            else {
                toast.error("Une erreur est survenue");
            }
            SetSubmitButtonIsDisabled(false);
        }
        );
    };

    function onHide () {
        setStartDate(new Date());
        setEndDate(new Date());
        setDescription('');
        setFile(null);
        props.setShow(false);
    }

    return (
        <Modal show={props.show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Déclarer une absence</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <div className="d-flex flex-row justify-content-between m-1">
                        <Form.Group className='d-flex flex-column w-50 me-2'>
                            <Form.Label>Date de début</Form.Label>
                            <Form.Control
                                type="date"
                                value={startDate.toISOString().split('T')[0]}
                                onChange={(e) => setStartDate(new Date(e.target.value))}
                            />
                        </Form.Group>
                        <Form.Group className='d-flex flex-column w-50 ms-2'>
                            <Form.Label>Date de fin</Form.Label>
                            <Form.Control
                                type="date"
                                value={endDate.toISOString().split('T')[0]}
                                onChange={(e) => setEndDate(new Date(e.target.value))}
                            />
                        </Form.Group>
                    </div>
                    <Form.Group className='m-1'>
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            value={description}
                            maxLength={textLengthLimit}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                        <div style={{ color: textLengthLimit - description.length < 25 ? 'red' : 'gray', fontSize: '10px', marginTop: '4px' }}>
                            {textLengthLimit - description.length} / {textLengthLimit}
                        </div>
                    </Form.Group>
                    <Form.Group className='m-1'>
                        <Form.Text>Les types de fichiers supportés sont : png, jpeg, jpg, pdf ou heic.</Form.Text>
                        <br/>
                        <Form.Text>Un fichier ne peut excéder 1 Mo.</Form.Text>
                        <Form.Control
                            type="file"
                            onChange={handleFileChange}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}> Annuler </Button>
                <Button variant="primary" disabled={isSubmitButtonDisable} onClick={handleSubmit}> 
                    {isSubmitButtonDisable ? (
                        'Veuillez patentier...'
                    ) : 'Soumettre' }
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeclarationAbsenceModal;

import { Status } from "./interfaces/AbsenceInterface";

export function statusColor(status: Status) {
    switch (status) {
    case Status.EXCUSE:
        return 'bg-success';
    case Status.NON_JUSTIFIE:
        return 'bg-danger';
    case Status.EN_ATTENTE:
        return 'bg-secondary';
    case Status.VU:
        return 'bg-warning';
    default:
        return 'bg-secondary';
    }
}

export function sortByDate(array: any[], param : string) {
    return array.sort((a, b) => {
        return new Date(b[param]).getTime() - new Date(a[param]).getTime();
    });
}

import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { Group } from '../interfaces/AuthentificationInterface';
import Header from '../components/shared/annuaire/Header';
import Filters from '../components/shared/annuaire/Filters';
import DataTable from "../components/shared/annuaire/DataTable";
import { FiltersInterface as FiltersType } from "../interfaces/AnnuaireInterface";


export default function Annuaire() {
    const auth = useAuth();
    const userGroup = auth.user?.profile.group as Array<Group>;

    const [filters, setFilters] = useState<FiltersType>({
        fullName: "",
        job: "",
        location: "",
        company: "",
        promotionSIGL: ""
    });

    const handleFilterChange = (newFilters: FiltersType) => {
        setFilters(newFilters);
    };

    if (userGroup.includes(Group.MajorLeader) || userGroup.includes(Group.Teacher)) {
        return (
            <>
                <Header />
                <Filters onFilter={handleFilterChange}/>
                <DataTable filters={filters} />
            </>
        );
    }
    else if (userGroup.includes(Group.Student)) {
        const studentId = auth.user?.profile.preferred_username;
        if (studentId === undefined) {
            return <Spinner animation="border" variant="primary" />;
        }
        return (
            <>
                <Header />
                <Filters onFilter={handleFilterChange}/>
                <DataTable filters={filters} />
            </>
        );
    }

    return <Spinner animation="border" variant="primary" />;
}

import React from "react";
import Help from '../../../assets/icons/help.svg';

function HelpHeader() {


    return (
        <div className="page-header d-flex align-items-center p-1 justify-content-between white-background rounded bg-light">
            <h1 className="flex-grow-1 text-center m-auto">Aide</h1>
            <img src={Help} alt="Aide" className="p-1" style={{width : '50px'}}/>
        </div>);
}

export default HelpHeader;

import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import EditTable from '../components/FFO/note/EditGrade/EditGradeTable';
import Header from '../components/FFO/note/Header';
import Table from '../components/FFO/note/NoteTable';
import Selector from '../components/FFO/note/Selector';
import HeaderStudent from '../components/student/note/Header';
import NoteTableStudent from '../components/student/note/NoteTable';
import TeacherTable from '../components/teacher/NoteTable';
import { Group } from '../interfaces/AuthentificationInterface';
import { CourseWithMean } from '../interfaces/CourseInterface';


function Notes() {
    const auth = useAuth();
    // set semester to 2 if it's the month between october and january else set it to 1
    const [semester, setSemester] = useState<number>(new Date().getMonth() >= 9 || new Date().getMonth() <= 0 ?
        2 : 1);
    const [promotion, setPromotion] = useState(new Date().getMonth() >= 9 || new Date().getMonth() <= 0 ?
        new Date().getFullYear() : new Date().getFullYear() + 1);
    const [course, setCourse] = useState<CourseWithMean | null>(null);
    const userGroup = auth.user?.profile.group as Array<Group>;

    if (userGroup.includes(Group.MajorLeader)) {
        return (
            <>
                <Header />
                {course ?
                    <>
                        <EditTable course={course} />
                    </>
                    :
                    <>
                        <Selector setPromotion={setPromotion} semester={semester} setSemester={setSemester} promotion={promotion} />
                        <Table promotion={promotion} semester={semester} setCourse={setCourse} />
                    </>

                }

            </>
        );
    }
    else if (userGroup.includes(Group.Student)) {
        const studentId = auth.user?.profile.preferred_username;
        if (studentId === undefined) {
            return <Spinner animation="border" variant="primary" />;
        }
        return (
            <>
                <HeaderStudent setSemester={setSemester} semester={semester} />
                <NoteTableStudent student={studentId} semester={semester} />
            </>
        );
    }
    else if (userGroup.includes(Group.Teacher)) {
        return <>
            <Header />
            {course ?
                <>
                    <EditTable course={course} />
                </>
                :
                <>
                    <TeacherTable setCourse={setCourse} promotion={promotion} />
                </>
            }

        </>;


    }
    return <Spinner animation="border" variant="primary" />;
}


export default Notes;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Absence, Declaration, Status } from "../../../interfaces/AbsenceInterface";
import { PageTab } from "../../../pages/Absences";
import Select from "react-select";
import { toast } from "react-toastify";

function Selector(props: { 
    setStudentSelected: (arg0: string) => void;
    tabs: string;
    promotion: string;
    setPromotion: (arg0: string) => void;
}) {
    interface selectorData {
        student: string;
        count: number;
    }

    const [students, setStudents] = useState<selectorData[]>([]);
    const auth = useAuth();

    const date = new Date();
    const year = date.getFullYear();
    // as we want to be syncrhronized with the api, the promotion will change the 1st of march
    const lastPromo = date.getMonth() < 2 ? year: year + 1;

    useEffect(() => {
        function sorting(elements: { status: Status, studentLogin: string }[]) : selectorData[] {
            return elements.reduce<{ student: string, count: number }[]>(
                function (acc, curr) {
                    let found = acc.find(element => element.student === curr.studentLogin);
                    if (found) {
                        found.count++;
                    } else {
                        acc.push({ student: curr.studentLogin, count: 1 });
                    }
                    return acc;
                }, []).sort((a, b) => b.count - a.count);
        }

        if (props.tabs === PageTab.Absence) {
            axios.get<Absence[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/absences/promotions`,
                { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
                .then(response => {
                    if (props.promotion === '') {
                        setStudents(sorting(response.data));
                    } else {
                        setStudents(sorting(response.data.filter(absence => absence.promotion.toString() === props.promotion)));
                    }
                }, error => {
                    toast.error("Une erreur est survenue");
                }
                );
        }
        else {
            axios.get<Declaration[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/declaration/all`,
                { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
                .then(response => {
                    setStudents(sorting(response.data));
                }, error => {
                    toast.error("Une erreur est survenue");
                }
                );
        }
    }, [auth.user?.access_token, props.tabs, props.promotion]);

    return (
        <div className="d-flex">
            <Select
                name="promoSelector"
                className="w-25 mt-2 me-2"
                onChange={(selectedPromotion) => props.setPromotion(selectedPromotion?.value || "")}
                defaultValue={{ label: "Toutes les promotions", value: "" }}
                options ={[{label : "Toutes les promotions", value : ""}, {label : lastPromo.toString(), value : lastPromo.toString()}, {label : (lastPromo - 1).toString(), value : (lastPromo - 1).toString()}]}
            />
            <Select
                name="absenceSelector"
                className="w-25 mt-2"
                onChange={(selectedStudent) => props.setStudentSelected(selectedStudent?.value || "")}
                defaultValue={{ label: "Tous les étudiants", value: "" }}
                options ={[{label : "Tous les étudiants", value : ""},...students.map((student) => ({ value: student.student, label: student.student + " (" + student.count + ")" })) ]}
            />
        </div>
    );
    
    
}

export default Selector;

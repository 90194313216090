import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import Table from 'react-bootstrap/Table';
import { useAuth } from "react-oidc-context";
import { sortByDate, statusColor } from "../../../../utils";
import { Declaration, Status } from "../../../../interfaces/AbsenceInterface";
import { DetailsModal } from "../../absence/DeclarationModal";
import { toast } from "react-toastify";

function DeclarationTableModal(props : {setShowModal: (showModal: boolean) => void}) {
    const [declarations, setdeclarations] = useState<Declaration[] | null>(null);
    const [declarationModal, setDeclarationModal] = useState<Declaration | null>(null);
    const auth = useAuth();

    useEffect(() => {
        axios.get<Declaration[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/declaration/all`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => setdeclarations(sortByDate(response.data.filter(declaration => declaration.status === Status.EN_ATTENTE), "startDate")), error => {
                toast.error("Une erreur est survenue");
            });
    }, [auth.user?.access_token, declarationModal]);

    return (
        <Modal show={true} onHide={() => props.setShowModal(false)} dialogClassName="modal-90w">
            <Modal.Header closeButton className="border-0"/>
            <Table borderless responsive className="rounded mt-2 overflow-hidden">
                <thead className="text-center">
                    <tr className="border-bottom">
                        <th className="fs-4">Login</th>
                        <th className="fs-4">Date de début</th>
                        <th className="fs-4">Date de fin</th>
                        <th className="fs-4">Statut</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="text-center align-middle">
                    {declarations?.map((declaration, index) => (
                        <tr key={index}>
                            <td>{declaration.studentLogin}</td>
                            <td>{new Date(declaration.startDate).toLocaleDateString('FR-fr')}</td>
                            <td>{new Date(declaration.endDate).toLocaleDateString('FR-fr')}</td>
                            <td className="align-items-center">
                                <span className={`d-block mx-auto w-50 p-2 rounded text-white ${statusColor(declaration.status)}`}>
                                    {declaration.status.toString()}
                                </span>
                            </td>
                            <td>
                                <Button variant="primary"
                                    disabled={declaration.status === Status.VU || declaration.status === Status.EXCUSE}
                                    onClick={() =>  setDeclarationModal(declaration)}>Détails</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <DetailsModal setDeclarationModal={setDeclarationModal} declarationModal={declarationModal}/>
        </Modal>
    );
}

export default DeclarationTableModal;

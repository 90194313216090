import React from "react";
import { Button } from "react-bootstrap";
import Grade from '../../../assets/icons/grade.svg';

function HeaderStudent(props: { setSemester: (arg0: number) => void; semester: number; }) {
    // when the user click on the button, we set the semester and the button is highlighted
    return (
        <div className="page-header d-flex align-items-center p-1 justify-content-between white-background rounded bg-light">
            <Button className="rounded m-2 fw-bold"
                variant={`${props.semester === 1 ? 'primary' : 'secondary'}`}
                onClick={() => {
                    props.setSemester(1);
                }}>S8</Button>
            <Button className="rounded m-2 fw-bold"
                variant={`${props.semester === 2 ? 'primary' : 'secondary'}`}
                onClick={() => {
                    props.setSemester(2);
                }} >S9</Button>
            <div className="right d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
                <h1 className="flex-grow-1 text-center m-auto">Notes</h1>
                <img src={Grade} alt="Logo notes" className="p-1" style={{ width: "50px" }} />
            </div>
        </div>);
}

export default HeaderStudent;

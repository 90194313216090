import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Table from 'react-bootstrap/Table';
import { useAuth } from "react-oidc-context";
import { Absence, Status } from "../../../interfaces/AbsenceInterface";
import { statusColor } from "../../../utils";
import { sortByDate } from "../../../utils";
import DetailsModal from "./AbsenceModal";

function AbsenceTable(props: {
    setFilteredAbsences: any;
    studentSelected: string;
    filteredAbsences: Absence[] | null | undefined;
    promotion: string;
}) {
    const [absences, setAbsences] = useState<Absence[] | null>(null);
    const [absenceModal, setAbsenceModal] = useState<Absence | null>(null);
    const auth = useAuth();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/absences/promotions`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => setAbsences(sortByDate(response.data, "courseDate")), error => {
                console.error(error);
            });
    }, [auth.user?.access_token, absenceModal]);

    useEffect(() => {
        props.setFilteredAbsences(absences?.filter(absence => (props.studentSelected === "" || absence.studentLogin === props.studentSelected) && (props.promotion === '' || absence.promotion.toString() === props.promotion)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [absences, props.studentSelected, props.promotion]);



    if (!absences)
        return (
            <Spinner animation="border" variant="primary" />
        );
    return (
        <>
            <Table borderless striped responsive className="rounded mt-2 overflow-hidden">
                <thead className="text-center">
                    <tr className="border-bottom">
                        <th className="fs-4">Login</th>
                        <th className="fs-4">Cours</th>
                        <th className="fs-4">Date</th>
                        <th className="fs-4">Temporalité</th>
                        <th className="fs-4">Statut</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="text-center align-middle">
                    {props.filteredAbsences?.map((absence, index) => (
                        <tr key={index}>
                            <td>{absence.studentLogin}</td>
                            <td>{absence.courseName}</td>
                            <td>{new Date(absence.courseDate).toLocaleDateString('FR-fr')}</td>
                            <td>{absence.declarationId ? 'a priori' : 'a posteriori'}</td>
                            <td className="align-items-center">
                                <div className={`d-block mx-auto w-50 p-2 rounded text-white ${statusColor(absence.status)}`}>
                                    {absence.status.toString()}
                                </div>
                            </td>
                            <td>
                                {absence.status !== Status.NON_JUSTIFIE && <Button variant={absence.status === Status.EN_ATTENTE ? 'primary' : 'secondary'} onClick={() => setAbsenceModal(absence)}>Détails</Button>}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <DetailsModal setAbsenceModal={setAbsenceModal} absenceModal={absenceModal}/>
        </>
    );
}

export default AbsenceTable;

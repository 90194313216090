import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import fileInfo from '../../../../assets/icons/fileInfo.svg';
import { Declaration, Status } from '../../../../interfaces/AbsenceInterface';
import DeclarationTableModal from '../declaration/DeclarationTableModal';
import { toast } from 'react-toastify';

function DeclarationFFO() {
    const navigate = useNavigate();
    const auth = useAuth();
    const [declarationCount, setDeclarationCount] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        axios.get<Declaration[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/declaration/all`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            setDeclarationCount(response.data.filter(declaration => declaration.status === Status.EN_ATTENTE).length);
        }
        ).catch(error => {
            toast.error("Une erreur est survenue");
        });
    }, [auth.user, showModal]);

    return (
        <Card className="card">
            <Card.Body className="d-flex flex-column">
                <Card.Title className="fw-bold px-2 fs-4">Déclarations</Card.Title>
                <div className="row g-2 align-items-centers">
                    <div className="col-4 text-danger fs-1 fw-bold text-center p-2">
                        {declarationCount}
                    </div>
                    <div className="col-5 fw-bold text-center align-self-center fs-6">
                        {declarationCount > 1 ? "Nouvelles déclarations" : "Nouvelle déclaration"}
                    </div>
                    <div className="col-3 text-end">
                        <Button variant="light" className="rounded-circle p-2" style={{ width: '50px', height: '50px' }} onClick={() => setShowModal(true)}>
                            <Image src={fileInfo} alt='Info élève' fluid></Image>
                        </Button>
                    </div>
                </div>
                <div className="d-flex flex-column p-2">
                    <Button variant="primary" onClick={() => navigate('absences/declaration')}>Voir toutes les déclarations</Button>
                </div>
            </Card.Body>
            {showModal && <DeclarationTableModal setShowModal={setShowModal}/>}
        </Card>
    );
}

export default DeclarationFFO;

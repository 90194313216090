import React from "react";
import { useContext } from "react";
import { View, ViewContext } from "../../pages/Syllabus";
import GeneralView from "../../components/Syllabus/views/GeneralView";
import SyllabusTableView from "../../components/Syllabus/views/SyllabusTableView";
import HeaderSyllabus from "../../components/Syllabus/components/Common/Header";
import { Spinner } from "react-bootstrap";
import SyllabusFillForm from "./views/Teacher/SyllabusFillFormView";
import SyllabusCreateForm from "./views/FFO/SyllabusCreateFormView";
import SyllabusAskModificationForm from "./views/FFO/SyllabusAskModificationFormView";

function SyllabusRouter() {
    const { currentView } = useContext(ViewContext);

    switch (currentView) {
    case View.GeneralView:
        return (
            <>
                <HeaderSyllabus/> 
                <GeneralView/>
            </>
        );
    case View.SyllabusTableView:
        return (
            <>
                <HeaderSyllabus/>
                <SyllabusTableView/>
            </>
        );
    case View.SyllabusFillFormView:
        return (
            <>
                <HeaderSyllabus/> 
                <SyllabusFillForm/>
            </>
        );
    case View.SyllabusCreateFormView:
        return (
            <>
                <HeaderSyllabus/>
                <SyllabusCreateForm/>
            </>
        );
    case View.SyllabusAskModificationFormView:
        return (
            <>
                <HeaderSyllabus/>
                <SyllabusAskModificationForm/>
            </>
        );
    default:
        return <Spinner animation="border" role="status"></Spinner>;
    }
}

export default SyllabusRouter;
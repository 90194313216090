import axios from 'axios';
import React, { useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { LastNote, StudentMean, medals } from "../../../../interfaces/NoteInterface";
import HelpButtonModal from '../../help/helpModal';
import { toast } from 'react-toastify';

function NoteStudent() {
    const auth = useAuth();
    const [notes, setNotes] = useState<LastNote[]>([]);
    const [mean, setMean] = useState<StudentMean>({mean: 0});
    const description = "Ce composant contient les informations quant à vos notes sur le S8 et le S9 ainsi que votre moyenne et votre classement. Une question ? Faites le nous savoir -> aide dans la barre de navigation.";
    const [screensize, setScreensize] = useState<'Small' | 'Big'
     >(window.innerWidth > 768 ? 'Big' : 'Small');

    useEffect(()=> {
        axios.get<LastNote[]>(`${process.env.REACT_APP_NOTE_API_URL}/v1/grades/lastgrades/${auth.user?.profile.preferred_username}`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            setNotes(response.data);
        }
        ).catch(error => {
            console.error(error);
            toast.error("Une erreur est survenue lors de la récupération des notes");
        });
    }, [auth.user?.profile.preferred_username, auth.user?.access_token]);

    let navigate = useNavigate();
    const lienNoteEleve = () =>{
        navigate(`notes`);
    };

    // when screen size is small, change the column size
    useState(() => {
        window.addEventListener('resize', () => {
            setScreensize(window.innerWidth > 768 ? 'Big' : 'Small');
        });
    });

    useEffect(()=> {
        axios.get<StudentMean>(`${process.env.REACT_APP_NOTE_API_URL}/v1/grades/mean/student/${auth.user?.profile.preferred_username}`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            setMean(response.data);
        }
        ).catch(error => {
            console.error(error);
            toast.error("Une erreur est survenue");
        });
    }, [auth.user?.profile.preferred_username, auth.user?.access_token]);

    function getMedal(position: number) {
        if (position && position <= 3 && position > 0) {
            return <><img src={medals.find(value => value.position === position)?.src} alt="" /></>;
        }
        return <></>;
    }

    function lastNotes(notes: LastNote[]) {
        const last_note = notes.sort((a, b) => b.gradeId - a.gradeId).slice(0, 3);
        return (
            <div className='container'>
                {last_note.map((eleve, index) => (
                    <div key={index} className="row border-bottom py-2 d-flex align-items-center">
                        <div className={`col-${screensize === 'Big' ? '4' : '5'} fw-semibold p-0 fs-${screensize === 'Big' ? '5' : '6'}`}>
                            {eleve.courseName}
                        </div>
                        <div className={`col-${screensize === 'Big' ? '2' : '1'} p-2`}>
                            {getMedal(eleve.position)}
                        </div>
                        <div className={`col-4 text-end fw-semibold text-center ms-auto fs-${screensize === 'Big' ? '5' : '6'}`}>
                            {eleve.value}
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    function voirToutesNotes() {
        return (
            <>
                <div className="container">
                    <div className="row">
                        <button type="button" className="btn btn-primary btn-block" onClick={() => lienNoteEleve()}>Voir toutes les notes</button>
                    </div>
                </div>
            </>
        );
    }


    return (
        <Card className="card">
            <Card.Body className="card-body p-4 d-flex flex-column">
                <Card.Title className="d-flex justify-content-between">
                    <h3 className="fw-bold">Notes</h3>
                    {mean.mean !== -1 && (
                        <h3 className="float-end fw-bold">
                            {mean.mean}
                        </h3>
                    )}
                </Card.Title>
                {notes.length ? (
                    lastNotes(notes)
                ) : (
                    <div className="card-text text-center">Pas de notes</div>
                )}
                <div className="d-flex flex-column p-2">
                    {voirToutesNotes()}
                </div>
                <div className='d-flex justify-content-end'>
                    <HelpButtonModal content={description} isCard={true}></HelpButtonModal>
                </div>
            </Card.Body>
        </Card>
    );
}

export default NoteStudent;

import React, { useEffect, useState } from "react";
import Spreadsheet, { CellBase, Matrix } from "react-spreadsheet";
import { CourseWithMean, Grade } from "../../../../interfaces/CourseInterface";
import { Student } from "../../../../interfaces/StudentInterface";
import "./grade.css";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { Modal, Button } from "react-bootstrap";
import { toast } from "react-toastify";

function IndividualTable(props: { course: CourseWithMean, cells?: Matrix<CellBase> }) {
    const [cells, setCells] = useState<(CellBase & { grade?: Grade, student?: Student } | undefined)[][]>([[]]);
    const [studentsCount, setStudentsCount] = useState<number>(0);
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [showDescriptionModal, setShowDescriptionModal] = useState<boolean>(false);

    const auth = useAuth();

    useEffect(() => {
        if(props.cells) {
            setCells(props.cells);
            setStudentsCount(props.cells.length);
            return;
        }

        axios.get<Student[]>(`${process.env.REACT_APP_SCOLARITE_API_URL}/student/students?promotion=${props.course.promotion}`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(students => {
                students.data.sort((a, b) => {
                    return a.login.localeCompare(b.login);
                });

                setStudentsCount(students.data.length);
                axios.get<Grade[]>(`${process.env.REACT_APP_NOTE_API_URL}/v1/grades/${props.course.courseId}`,
                    { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
                    .then(response => {
                        if (response.data && response.data.length !== 0) {
                            setIsUpdate(true);
                        }
                        setCells(students.data.map(student => {
                            const grade = response.data.find(grade => grade.studentLogin === student.login);

                            return [
                                { value: student.firstName + " " + student.lastName, readOnly: true, student: student }, 
                                { value: student.group, readOnly: true }, 
                                { className:"edit-grade", value: grade?.value.toString(), grade: grade }, 
                                { value: grade?.value ? "✅" : "❌", readOnly: true }];
                        }));
                    });
            }, error => {
                toast.error("Une erreur est survenue");
            });
    }, [auth.user?.access_token, props]);

    function checkGrades(data: Matrix<CellBase>) {
        const res = data.map(row => {
            row = row.slice(0, 4);
            if (row[2]?.value === undefined || row[2]?.value === "") {
                row[3] = ({ value: "❌" });
            } else {

                row[2].value = row[2].value.replace(",", ".");
                const grade = Number(row[2]?.value);
                if (row[3]) {
                    row[3] = { value: Number.isNaN(grade) || grade < 0 || grade > 20 ? "❌" : "✅" };
                }
            }
            return row;
        }).slice(0, studentsCount);

        setCells(res);
    }

    function handleSubmitButton() {
        if (isUpdate) {
            setShowDescriptionModal(true);
        } else {
            submitGrades();
        }
    }

 
    async function submitGrades(description?: string) {
        if (!cells.reduce((acc, row) => {
            return acc && row[3]?.value === "✅";
        }, true)) {
            return;
        }
        if (isUpdate) {
            if (!description || description === "") {
                return;
            }
            // -- Grade update --
            try {
                const response = await axios.patch(`${process.env.REACT_APP_NOTE_API_URL}/v1/grades/batchUpdate`, {
                    grades: cells.map(row => {
                        return {
                            value: Number(row[2]?.value),
                            gradeId: row[2]?.grade?.gradeId
                        };
                    }),
                    courseId: props.course.courseId,
                    teacherLogin: auth.user?.profile.preferred_username,
                    message: description
                },
                { headers: { Authorization: `Bearer ${auth.user?.access_token}` } });
                if (response.status === 200 || response.status === 201) {
                    toast.success("Les notes ont été modifiées");
                    window.location.reload();
                } else {
                    console.error(response);
                    toast.error("Une erreur est survenue");
                }
            } catch (error) {
                console.error(error);
                toast.error("Une erreur est survenue");
            }
        } else {
            // -- Grade creation --
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_NOTE_API_URL}/v1/grades/batch`, 
                    {
                        grades: cells.map(row => {
                            return {
                                courseId: props.course.courseId,
                                studentLogin: row[0]?.student?.login,
                                value: Number(row[2]?.value)
                            };
                        }),
                        courseId: props.course.courseId,
                        teacherLogin: auth.user?.profile.preferred_username,
                    },
                    { headers: { Authorization: `Bearer ${auth.user?.access_token}` } }
                );
            
                if (response.status === 200 || response.status === 201) {
                    toast.success("Les notes ont été modifiées");
                    window.location.reload();
                } else {
                    console.error(response);
                    toast.error("Une erreur est survenue");
                }
            } catch (error) {
                console.error(error);
                toast.error("Une erreur est survenue");
            }
        }
    }

    return <div className="d-flex flex-column">
        <Spreadsheet
            onChange={(data: Matrix<CellBase>) => {
                checkGrades(data);
            }}
            data={cells}
            columnLabels={["Etudiant", "Groupe", "Note", "Format valide"]}
            className='bg-light text-dark'
        />
        <Button className='m-2' onClick={handleSubmitButton}>
                    Envoyer
        </Button>
        <Modal show={showDescriptionModal} onHide={() => setShowDescriptionModal(false)}>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>Raison de la modification (obligatoire)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea id="description" className="w-100" rows={6} />
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button variant="success" className="me-1 d-flex"
                        onClick={() => submitGrades((document.getElementById("description") as HTMLTextAreaElement).value)}>Envoyer</Button>
                </Modal.Footer>
            </Modal>
    </div>;

}

export default IndividualTable;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner, Table } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { Declaration } from "../../../interfaces/AbsenceInterface";
import { sortByDate, statusColor } from "../../../utils";
import DeclarationAbsenceModal from "./DeclarationAbsenceModal";
import { toast } from "react-toastify";

function DeclarationStudentTable(props: {
    setFiltereddeclarations: any; studentSelected: string, filtereddeclarations: Declaration[] | null | undefined
}) {
    const [declarations, setdeclarations] = useState<Declaration[] | null>(null);
    const [declarationModal, setDeclarationModal] = useState<Declaration | null>(null);
    const [show, setShow] = useState<boolean>(false);
    const auth = useAuth();

    useEffect(() => {
        axios.get<Declaration[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/declaration/student/${auth.user?.profile.preferred_username}`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => setdeclarations(sortByDate(response.data, 'startDate')), error => {
                toast.error("Une erreur est survenue");
            });
    }, [auth.user?.access_token, auth.user?.profile.preferred_username, show]);

    useEffect(() => {
        props.setFiltereddeclarations(declarations?.filter(declaration => props.studentSelected === "" || declaration.studentLogin === props.studentSelected));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declarations, props.studentSelected]);

    const DetailsModal = () => {
        return (
            <Modal show={declarationModal !== null} onHide={() => setDeclarationModal(null)}>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between my-1">
                        <span className="fw-bold">Début : {new Date(declarationModal?.startDate ?? '').toLocaleDateString('FR-fr')}</span>
                        <span className="fw-bold">Fin : {new Date(declarationModal?.endDate ?? '').toLocaleDateString('FR-fr')}</span>
                    </div>
                    <span className="fw-bold">Description :</span>
                    <textarea disabled className="w-100" defaultValue={declarationModal?.description} rows={6}/>
                    <span className="fw-bold">Pièce jointe : {declarationModal?.hasAttachement ? 'Oui' : 'Non'}</span>
                </Modal.Body>
            </Modal>
        );
    };

    if (!declarations)
    {
        return (<Spinner animation="border" variant="primary" />);
    }

    return (<>
        <div className="d-flex justify-content-end">
            <Button variant="primary" className="m-2" onClick={() => {setShow(true);}}>
            Déclarer une absence
            </Button>
            <DeclarationAbsenceModal show={show} setShow={setShow}/>
        </div>
        <Table className="table table-borderless rounded my-2 overflow-hidden">
            <thead className="text-center">
                <tr className="border-bottom">
                    <th className="fs-4">Date de début</th>
                    <th className="fs-4">Date de fin</th>
                    <th className="fs-4">Statut</th>
                    <th></th>
                </tr>
            </thead>
            <tbody className="text-center align-middle">
                {props.filtereddeclarations?.map((declaration, index) => (
                    <tr key={index}>
                        <td>{new Date(declaration.startDate).toLocaleDateString('FR-fr')}</td>
                        <td>{new Date(declaration.endDate).toLocaleDateString('FR-fr')}</td>
                        <td className="align-items-center">
                            <span className={`d-block mx-auto w-50 p-2 rounded text-white ${statusColor(declaration.status)}`}>
                                {declaration.status.toString()}
                            </span>
                        </td>
                        <td>
                            <Button variant="primary" onClick={() => setDeclarationModal(declaration)}>Détails</Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
        <DetailsModal/>
    </>

    );
}

export default DeclarationStudentTable;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Table from 'react-bootstrap/Table';
import { useAuth } from "react-oidc-context";
import { Course } from "../../../interfaces/CourseInterface";
import { Module } from "../../../interfaces/ModuleInterface";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

function NoteTableStudent(props: { student: string , semester: number}) {
    const [grades, setGrades] = useState<Module[] | null>(null);
    const auth = useAuth();
    const [screensize, setScreensize] = useState<'Small' | 'Big'>(window.innerWidth > 768 ? 'Big' : 'Small');

    useState(() => {
        window.addEventListener('resize', () => {
            setScreensize(window.innerWidth > 768 ? 'Big' : 'Small');
        });
    });

    useEffect(() => {
        // TODO: Add semester
        axios.get<Module[]>(`${process.env.REACT_APP_NOTE_API_URL}/v1/grades/student/${props.student}?semester=${props.semester}`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => {setGrades(response.data);}, error => 
            {
                console.error(error);
                toast.error("Une erreur est survenue");
            });
    }, [auth.user?.access_token,props.student, props.semester]);

    function computeMean(courses?: Course[]) : number {
        if (!courses)
            return 0;
        let gradeSum = 0;
        let coefficientSum = 0;
        courses.forEach(course => {
            if (course.grade) {
                gradeSum += course.grade.value * course.coefficient;
                coefficientSum += course.coefficient;
            }
        }
        );
        if(coefficientSum === 0)
            return 0;
        return gradeSum / coefficientSum;
    }

    if (!grades)
        return (
            <Spinner animation="border" variant="primary" />
        );

    return (
        <>
            {screensize === 'Small' && (<Link to="/"> <Button className="mt-2">Retour</Button> </Link >)}
            <Accordion alwaysOpen className="my-2 rounded" defaultActiveKey={Array.from({ length: grades.length }, (_, i) => i.toString())}>
                {grades.map((module, index) => {
                    return (
                        <Accordion.Item eventKey={index.toString()} key={index} className="bg-light show">
                            <Accordion.Header className="bg-light d-block">
                                <span className="">{module.name}</span>
                                <span className="position-absolute start-50">
                                    {screensize === 'Big' && computeMean(module.courses) !== 0 ? `Moyenne : ${computeMean(module.courses).toFixed(2).toString().replace(".", ",")}` : ''}
                                </span>
                            </Accordion.Header>
                            <Accordion.Body className="bg-light">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="text-center">
                                            <th>Cours</th>
                                            {screensize === 'Big' && <th>Coefficient</th>}
                                            <th>Moyenne{screensize === 'Big' && " de la promotion"}</th>
                                            <th>Rang</th>
                                            <th>Note</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {module.courses?.map((course, courseIndex) => {
                                            return (
                                                <tr className="text-center" key={courseIndex}>
                                                    <td>{course.courseName}</td>
                                                    {screensize === 'Big' && <td>{course.coefficient.toString().replace(".", ",")}</td>}
                                                    <td>{course.mean ? course.mean.toString().replace(".", ",") : ''}</td>
                                                    <td>{course.grade?.position}</td>
                                                    <td className="fw-bold">{course.grade?.value.toString().replace(".", ",")}</td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    );
                })}
            </Accordion>
            <div className="d-flex justify-content-end bg-primary p-3 sticky-footer rounded text-light rounded">
                <div className='d-flex flex-column justify-content-between p-2'>
                    <strong className="fs-4">{`Moyenne générale :`}</strong>
                </div>
                <div className='d-flex flex-column p-2 px-4'>
                    <strong className="fs-4 text-end">{computeMean(grades.map(m => m.courses).flat(1).filter(course => course !== undefined) as Course[]).toFixed(2).toString().replace(".", ",") ?? "Pas de moyenne"}</strong>
                </div>
            </div>
        </>
    );
}

export default NoteTableStudent;

import React from 'react';
import TeacherTable from '../components/FFO/teacher/teacherTable';
import Header from '../components/FFO/teacher/header';
import { useAuth } from 'react-oidc-context';
import { Group } from '../interfaces/AuthentificationInterface';

function Teachers() {
    const auth = useAuth();
    const userGroup = auth.user?.profile.group as Array<Group>;

    if (userGroup.includes(Group.MajorLeader)) {
        return (
            <>
                <Header setTab={undefined} tab={''} />
                <div style={{ marginTop: '10px' }}>
                    <TeacherTable />
                </div>
            </>
        );
    }

    return null;
}

export default Teachers;
import React from "react";
import Admin from '../../../assets/icons/admin.svg';

function TeacherHeader(props : {setTab : any, tab : string}) {


    return (
        <div className="page-header d-flex align-items-center p-1 justify-content-between white-background rounded bg-light">
            <h1 className="flex-grow-1 text-center m-auto">Admin</h1>
            <img src={Admin} alt="Logo absence" className="p-1" style={{width : '50px'}}/>
        </div>);
}

export default TeacherHeader;

import React from "react";
import { useAuth } from "react-oidc-context";
import { useState } from "react";
import SyllabusRouter from "../components/Syllabus/SyllabusRouter";
import { Group } from "../interfaces/AuthentificationInterface";
import { PromotionUtils } from "../utils/PromotionUtils";

const getHighestRole = (userGroups: Group[]): Group => {
    if (userGroups.includes(Group.MajorLeader)) {
        return Group.MajorLeader;
    } else if (userGroups.includes(Group.Teacher)) {
        return Group.Teacher;
    }

    return Group.Student;
};

export enum View {
    GeneralView = "GeneralView",
    SyllabusTableView = "SyllabusTableView",
    SyllabusFillFormView = "SyllabusFillFormView",
    SyllabusCreateFormView = "SyllabusCreateFormView",
    SyllabusAskModificationFormView = "SyllabusAskModificationFormView",
}

export const ViewContext = React.createContext({
    role: Group.Student,
    currentView: View.GeneralView,
    selectedSyllabusCourse: -1,
    currentPromo: PromotionUtils.getCurrentPromotion().toString(),
    setRole: (role: Group) => {},
    setCurrentView: (view: View) => {},
    setSelectedSyllabusCourse: (course: number) => {},
    setCurrentPromo: (promo: string) => {}
});

function Syllabus() {
    const auth = useAuth();
    const userGroups = auth.user?.profile["group"] as Array<Group>;
    const userPromo = auth.user?.profile["promotion"] as string;

    const [currentView, setCurrentView] = useState<View>((getHighestRole(userGroups) !== Group.Student) ? View.SyllabusTableView : View.GeneralView);
    const [role, setRole] = useState<Group>(getHighestRole(userGroups));
    const [selectedSyllabusCourse, setSelectedSyllabusCourse] = useState<number>(-1);
    const [currentPromo, setCurrentPromo] = useState<string>(userPromo ? userPromo : PromotionUtils.getCurrentPromotion().toString());
    const value = { role, currentView, selectedSyllabusCourse, currentPromo, setRole, setCurrentView, setSelectedSyllabusCourse, setCurrentPromo };
    
    return (
        <ViewContext.Provider value={value}>
            <div>
                <SyllabusRouter/>
            </div>
        </ViewContext.Provider>
    );
}

export default Syllabus;

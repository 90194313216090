import { Tooltip } from 'bootstrap';
import { useEffect } from 'react';

function TooltipManager() {
    useEffect(() => {
    // Sélectionnez tous les éléments avec les attributs data-bs-toggle="tooltip"
        const tooltipTriggerList = document.querySelectorAll('[data-bs-tooltip="tooltip"]');

        // Initialisation des tooltips pour chaque élément
        tooltipTriggerList.forEach(function (tooltipTriggerEl) {
            new Tooltip(tooltipTriggerEl, {
                trigger: 'hover'
            });
        });
    }, []);

    return null;
}

export default TooltipManager;

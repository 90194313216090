import React, { ChangeEvent, useState } from "react";
import { FiltersInterface } from "../../../interfaces/AnnuaireInterface";
import "./styles/Filters.css";

interface FiltersProps {
    onFilter: (filters: FiltersInterface) => void;
}

const Filters: React.FC<FiltersProps> = ({ onFilter }) => {
    const [filters, setFilters] = useState<FiltersInterface>({
        fullName: "",
        job: "",
        location: "",
        company: "",
        promotionSIGL: ""
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const newFilters = { ...filters, [name]: value };
        setFilters(newFilters);
        onFilter(newFilters);
    };

    return (
        <div className="filters-container">
            <input type="text" name="fullName" placeholder="Nom" value={filters.fullName} onChange={handleChange} className="filter-input" />
            <input type="text" name="job" placeholder="Fonction" value={filters.job} onChange={handleChange} className="filter-input" />
            <input type="text" name="location" placeholder="Pays" value={filters.location} onChange={handleChange} className="filter-input" />
            <input type="text" name="company" placeholder="Entreprise" value={filters.company} onChange={handleChange} className="filter-input" />
            <input type="text" name="promotionSIGL" placeholder="Promotion" value={filters.promotionSIGL} onChange={handleChange} className="filter-input" />
        </div>
    );
};

export default Filters;

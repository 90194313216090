import axios from "axios";
import React, { useEffect, useState } from "react";
import { Accordion, Button, Image, Modal, Table } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useAuth } from "react-oidc-context";
import trash from '../../../assets/icons/delete.svg';
import edit from '../../../assets/icons/edit.svg';
import grade from '../../../assets/icons/grade_white.svg';
import { Course, CourseWithMean } from "../../../interfaces/CourseInterface";
import { Module } from "../../../interfaces/ModuleInterface";
import { Teacher } from "../../../interfaces/TeacherInterface";
import AddCourseModal from "./AddCourseModal";
import EditCourseModal from "./EditCourseModal";
import EditModuleModal from "./EditModuleModal";
import { toast } from "react-toastify";

function NoteTable(props: { promotion: number , semester: number, setCourse: (id: CourseWithMean) => void}) {
    const [modules, setModules] = useState<Module[] | null>(null);
    const [editCourse, setEditCourse] = useState<Course | null>(null);
    const [editModule, setEditModule] = useState<Module | null>(null);
    const [addCourseModuleId, setAddCourse] = useState<number>(0);
    const [courseToDelete, setCourseToDelete] = useState<Course | null>(null);
    const [moduleToDelete, setModuleToDelete] = useState<Module | null>(null);
    const [showDeleteModuleModal, setShowDeleteModuleModal] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const auth = useAuth();

    useEffect(() => {
        axios.get<Module[]>(`${process.env.REACT_APP_SCOLARITE_API_URL}/module/getmodules?withCourses=true&promotion=${props.promotion}&semester=${props.semester}`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => {
                setModules(response.data);
            }, error => {
                toast.error("Une erreur est survenue");
            } 
            );
    }, [auth.user?.access_token, props.promotion, props.semester, refresh]);

    /**
     * Sets the course and module to be deleted and opens the delete course modal.
     * @param course - The course to be deleted.
     * @param module - The module to be deleted.
     */
    function handleDeleteCourseModal( module: Module, course: Course) {
        setCourseToDelete(course);
        setModuleToDelete(module);
    }

    /**
     * Sets the module to be deleted and opens the delete module modal.
     * @param module - The module to be deleted.
     */
    function handleDeleteModuleModal(module: Module) {
        setModuleToDelete(module);
        setShowDeleteModuleModal(true);
    }

    function ModalDeleteCourse () {
        if (!courseToDelete || !moduleToDelete)
            return null;
        return  (
            <Modal show={courseToDelete !== null && moduleToDelete !== null} onHide={() =>
            {
                setCourseToDelete(null);
                setModuleToDelete(null);
            }
            }>
                <Modal.Header closeButton>
                    <Modal.Title>Supprimer le cours</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Êtes-vous sûr de vouloir supprimer le cours {courseToDelete.courseName} du module {moduleToDelete.name} ?</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() =>
                    {
                        setCourseToDelete(null);
                        setModuleToDelete(null);

                    }}
                    >Annuler</Button>
                    <Button variant="danger" onClick={() => {
                        if (moduleToDelete && courseToDelete)                            handleDeleteCourse(moduleToDelete, courseToDelete);
                        setCourseToDelete(null);
                        setModuleToDelete(null);
                    }}
                    >Supprimer</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function ModalDeleteModule () {
        if (!moduleToDelete)
            return null;
        return  (
            <Modal show={moduleToDelete !== null} onHide={() =>
            {
                setModuleToDelete(null);
                setShowDeleteModuleModal(false);
            }
            }>
                <Modal.Header closeButton>
                    <Modal.Title>Supprimer le module</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Êtes-vous sûr de vouloir supprimer le module {moduleToDelete.name} et tout les cours associés ?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() =>
                    {
                        setModuleToDelete(null);
                        setShowDeleteModuleModal(false);
                    }}
                    >Annuler</Button>
                    <Button variant="danger" onClick={() => {
                        if (moduleToDelete)
                            handleDeleteModule(moduleToDelete);
                        setModuleToDelete(null);
                        setShowDeleteModuleModal(false);
                    }}
                    >Supprimer</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    async function handleDeleteCourse(module : Module, course : Course) {

        await Promise.all(course.teachers?.map(async (teacher: Teacher) => {
            return axios.post(`${process.env.REACT_APP_SCOLARITE_API_URL}/teacher/removeCourse/${teacher.teacherLogin}`, {
                courseId: course.courseId
            }, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } });
        })).then(error => {
            console.error(error);
            toast.error("Une erreur est survenue");
        });

        await axios.put(`${process.env.REACT_APP_SCOLARITE_API_URL}/module/${module.moduleId}/removeCourse/`,
            {courseId : course.courseId},
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } });

        await axios.delete(`${process.env.REACT_APP_SCOLARITE_API_URL}/course/${course.courseId}`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } }).then(error => {
            console.error(error);
            toast.error("Une erreur est survenue");
        });

        setRefresh(!refresh);
    }

    async function handleDeleteModule(module: Module) {
        if (module.courses) {
            await Promise.all(module.courses.map(async (course: Course) => {
                await handleDeleteCourse(module, course);
            }));
        }

        await axios.delete(`${process.env.REACT_APP_SCOLARITE_API_URL}/module/${module.moduleId}`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } }).then(error => {
            console.error(error);
            toast.error("Une erreur est survenue");
        });

        window.location.reload();
    }

    if (!modules)
        return <Spinner animation="border" variant="primary" />;

    return (
        <>
            <Accordion alwaysOpen className="my-2 rounded" defaultActiveKey={Array.from({length: modules.length}, (_, i) => i.toString())}>
                {modules.map((module, index) => {
                    return (
                        <Accordion.Item eventKey={index.toString()} key={index} className="bg-light show">
                            <Accordion.Header className="bg-light d-block">
                                <span className="">{module.name}</span>

                            </Accordion.Header>
                            <Accordion.Body className="bg-light">
                                <div className="d-flex">
                                    <span className="me-auto">{module.description}</span>
                                    <Button variant="primary" className="ms-auto my-2" onClick={() => setAddCourse(module.moduleId)}>Ajouter un cours</Button>
                                    <Button variant="primary" className="m-2" onClick={() => setEditModule(module)}>Modifier le module</Button>
                                    <Button variant="danger" className="my-2" onClick={() => handleDeleteModuleModal(module)}>Supprimer le module</Button>
                                </div>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th className="col-md-auto">Cours</th>
                                            <th className="col">Coefficient</th>
                                            <th className="col-md-auto">Professeur</th>
                                            <th className="col-lg-2">Moyenne / Ecart type</th>
                                            <th className="col-lg-2">Edition</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {module.courses?.map((course, courseIndex) => {
                                            return (
                                                <tr key={courseIndex} className="align-middle">
                                                    <td>{course.courseName}</td>
                                                    <td>{course.coefficient}</td>
                                                    <td>{course.teachers.map((t) => (`${t.firstName} ${t.lastName}`)).join(', ')}</td>
                                                    <td>{course.mean} / {course.standardDeviation}</td>
                                                    <td>
                                                        <div>
                                                            <Button variant="primary" className="m-2 p-0" onClick={() => props.setCourse(course)}><Image src={grade} fluid alt="Editer les notes" style={{width: "30px"}}/></Button>
                                                            <Button variant="secondary" className="m-2 p-0" onClick={() => setEditCourse(course)}><Image src={edit} fluid alt="Editer le cours" style={{width: "30px"}}/></Button>
                                                            {!course.mean && <Button variant="danger" className="m-2 p-0" onClick={() => handleDeleteCourseModal(module, course)}><Image src={trash} fluid alt="Supprimer" style={{width: "30px"}}/></Button>}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    );
                })}
            </Accordion>
            {editModule && <EditModuleModal promotion={props.promotion} editModule={editModule} setEditModule={setEditModule} refresh={refresh} setRefresh={setRefresh} />}
            {editCourse && <EditCourseModal promotion={props.promotion} editCourse={editCourse} setEditCourse={setEditCourse} refresh={refresh} setRefresh={setRefresh} />}
            {addCourseModuleId !== 0 && <AddCourseModal promotion={props.promotion} addCourseModuleId={addCourseModuleId} setAddCourse={setAddCourse} refresh={refresh} setRefresh={setRefresh} semester={props.semester}/>}
            {!!courseToDelete && !!moduleToDelete && ModalDeleteCourse()}
            {!!moduleToDelete && showDeleteModuleModal && ModalDeleteModule()}
        </>
    );
}

export default NoteTable;

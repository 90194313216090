import React from "react";
import axios from "axios";
import { Module } from "../../../interfaces/ModuleInterface";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { toast } from "react-toastify";

export default function EditModuleModal(props : { promotion: number, editModule: Module | null, setEditModule: (module: null) => void, refresh: boolean, setRefresh: (refresh: boolean) => void }) {
    console.log(props.editModule);
    const [name, setName] = useState<string>(props.editModule?.name || "");
    const [description, setDescription] = useState<string>(props.editModule?.description || "");
    const auth = useAuth();

    const handleEditModule = (event : any) => {
        event.preventDefault();
        if (name === "") {
            return;
        }
        axios.patch(`${process.env.REACT_APP_SCOLARITE_API_URL}/module/${props.editModule?.moduleId}`, {
            name: name,
            description: description,
            promotion: props.promotion,
        }, { headers: { Authorization: `Bearer ${auth.user?.access_token}`}})
            .then(() => {
                props.setEditModule(null);
                props.setRefresh(!props.refresh);
            }, error => {
                toast.error("Une erreur est survenue");
            });
    };

    return (
        <Modal show={props.editModule !== null} onHide={() => props.setEditModule(null)}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title>Editer le module</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleEditModule}>
                    <Form.Label>Nom :</Form.Label>
                    <Form.Control value={name} onChange={(e) => setName(e.target.value)}/>
                    <Form.Label>Description :</Form.Label>
                    <Form.Control as="textarea" rows={3} value={description} onChange={(e) => setDescription(e.target.value)}/>
                    <div className="d-flex justify-content-end m-2">
                        <Button type="submit">Valider</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

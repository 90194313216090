import React from "react";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";
import AbsenceFFO from "../components/FFO/dashboard/absence/Absence";
import DeclarationFFO from "../components/FFO/dashboard/declaration/Declaration";
import AbsenceStudent from "../components/student/dashboard/absence/Absence";
import Carousel from "../components/student/dashboard/caroussel/Carousel";
import NoteStudent from "../components/student/dashboard/notes/Notes";
import Presence from "../components/student/dashboard/presence/Presence";
import { Group } from "../interfaces/AuthentificationInterface";

function Dashboard() {
    const auth = useAuth();
    const userGroup = auth.user?.profile.group as Array<Group>;

    if (userGroup.includes(Group.MajorLeader)) {
        return <main>
            <div className="row">
                <div className="col-md-6 col-xl-3 mb-4">
                    <AbsenceFFO />
                </div>
                <div className="col-md-6 col-xl-3 mb-4">
                    <DeclarationFFO />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-xl-3 mb-4">
                    <Carousel />
                </div>
            </div>
        </main>;
    }
    else if(userGroup.includes(Group.Teacher)) {
        return <Navigate to="/notes"/>;
    }

    return <main>
        <div className="row">
            <div className="col-md-6 col-xl-3 mb-4">
                <Presence />
            </div>
            <div className="col-md-6 col-xl-3 mb-4">
                <Carousel />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6 col-xl-3 mb-4 mt-4">
                <AbsenceStudent/>
            </div>
            <div className="col-md-6 col-xl-3 mb-4 mt-4">
                <NoteStudent/>
            </div>
        </div>
    </main>;
}

export default Dashboard;

import React from 'react';
import Header from '../components/student/help/header';
import HelpForm from '../components/student/help/form';

function Help() {
    return (
        <>
            <Header/>
            <div style={{ marginTop: '10px' }}>
                <HelpForm />
            </div>
        </>
    );
}

export default Help;

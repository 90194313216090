import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import fileInfo from '../../../../assets/icons/fileInfo.svg';
import { Absence } from '../../../../interfaces/AbsenceInterface';
import AbsenceTableModal from '../absence/AbsenceTableModal';
import { toast } from 'react-toastify';

interface absentStudent {
    studentLogin: string;
    count: number;
}

function AbsenceFFO() {
    const navigate = useNavigate();
    const auth = useAuth();
    const [studentSelected, setStudentSelected] = useState<string>("");
    const [absentStudents, setAbsentStudents] = useState<absentStudent[]>([]);
    const [absenceCount, setAbsenceCount] = useState<number>(0);
    const [absenceModalShow, setAbsenceModalShow] = useState<boolean>(false);

    function colorOfAbsence(nbAbsences: number) {
        const color = Math.round(nbAbsences * 255 / 10);
        return `rgb(${color}, ${255 - color}, 0)`;
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/absences/promotion/count?limit=3`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            setAbsentStudents(response.data);
        }
        ).catch(error => {
            toast.error("Une erreur est survenue");
        });

        axios.get<Absence[]>(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/absences/promotions?status=En%20attente`, {
            headers: {
                Authorization: `Bearer ${auth.user?.access_token}`
            }
        }).then(response => {
            setAbsenceCount(response.data.length);
        }
        ).catch(error => {
            toast.error("Une erreur est survenue");
        });
    }, [auth.user, absenceModalShow]);

    function handleStudentDetails(studentLogin: string) {
        setStudentSelected(studentLogin);
        setAbsenceModalShow(true);
    }

    function resetModalValues() {
        setStudentSelected("");
        setAbsenceModalShow(false);
    }

    const DetailsModal = () => {
        return (
            <Modal show={absenceModalShow}
                dialogClassName="modal-90w"
                onHide={() => resetModalValues()}>
                <Modal.Header closeButton className="border-0">
                    <Modal.Title>{studentSelected === "" ? "Absences en Attente" : `Absences de ${studentSelected}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AbsenceTableModal studentSelected={studentSelected}/>
                </Modal.Body>
            </Modal>
        );
    };

    return (
        <>
            <Card className="card">
                <Card.Body className="d-flex flex-column">
                    <Card.Title className="fw-bold px-2 fs-4 mx-2">Absences</Card.Title>
                    <div className="row g-2 align-items-centers">
                        <div className="col-3 text-danger fs-1 fw-bold text-center p-2">
                            {absenceCount}
                        </div>
                        <div className="col-6 fw-bold text-center align-self-center fs-6">
                            {absenceCount > 1 ? "Nouvelles absences" : "Nouvelle absence"}
                        </div>
                        <div className="col-3 text-end">
                            <Button variant="light" className="rounded-circle p-2" style={{ width: '50px', height: '50px' }} onClick={() => setAbsenceModalShow(true)}>
                                <Image src={fileInfo} alt='Info élève' fluid></Image>
                            </Button>
                        </div>
                    </div>

                    {absentStudents.map((student, index) => {
                        return <div key={index} className="row g-2 border-bottom align-items-center">
                            <div className="col-3 absence-color fw-semibold text-center p-2 fs-4"
                                style={{color: `${colorOfAbsence(student.count)}`}}>
                                {student.count}
                            </div>
                            <div className="col-6 fw-semibold text-center p-2">{student.studentLogin}
                            </div>
                            <div className="col-3 text-end py-2">
                                <Button variant="light" className="rounded-circle p-1" style={{ width: '40px', height: '40px' }} onClick={() => handleStudentDetails(student.studentLogin)}>
                                    <Image src={fileInfo} fluid alt='Détails'></Image>
                                </Button>
                            </div>
                        </div>;
                    })}
                    <div className="d-flex flex-column p-2">
                        <Button variant="primary" onClick={() => navigate('absences')
                        }>Voir toutes les absences</Button>
                    </div>
                </Card.Body>
            </Card>
            <DetailsModal />
        </>
    );
}

export default AbsenceFFO;

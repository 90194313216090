import React from "react";
import { Table } from "react-bootstrap";
import { Syllabus } from "../../../../interfaces/SyllabusInterface";
import { Course } from "../../../../interfaces/CourseInterface";

function VolumeCourseInfoTable(props: { syllabus: Syllabus; course: Course }) {
    return (
        <Table striped bordered hover>
            <tbody>
                <tr>
                    <td>Volume horaire MOOC</td>
                    <td>{props.syllabus.hoursMooc}</td>
                </tr>
                <tr>
                    <td>Volume horaire CM</td>
                    <td>{props.syllabus.hoursCm}</td>
                </tr>
                <tr>
                    <td>Volume horaire CM/TD</td>
                    <td>{props.syllabus.hoursCmTd}</td>
                </tr>
                <tr>
                    <td>Volume horaire TD</td>
                    <td>{props.syllabus.hoursTd}</td>
                </tr>
                <tr>
                    <td>Volume horaire Coaching</td>
                    <td>{props.syllabus.hoursCoaching}</td>
                </tr>
                <tr>
                    <td>Volume horaire Examen</td>
                    <td>{props.syllabus.hoursExams}</td>
                </tr>
                <tr>
                    <td>Volume horaire Travail Personnel</td>
                    <td>{props.syllabus.hoursPersonalWork}</td>
                </tr>
                <tr>
                    <td>Volume total Face-à-Face</td>
                    <td>
                        {props.syllabus.hoursMooc +
              props.syllabus.hoursCm +
              props.syllabus.hoursCmTd +
              props.syllabus.hoursTd +
              props.syllabus.hoursCoaching}
                    </td>
                </tr>
                <tr>
                    <td>Volume Horaire Total</td>
                    <td>
                        {props.syllabus.hoursMooc +
              props.syllabus.hoursCm +
              props.syllabus.hoursCmTd +
              props.syllabus.hoursTd +
              props.syllabus.hoursCoaching +
              props.syllabus.hoursExams +
              props.syllabus.hoursPersonalWork}
                    </td>
                </tr>
            </tbody>
        </Table>
    );
}

export default VolumeCourseInfoTable;

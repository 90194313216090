import {CourseWithMean} from "../../../interfaces/CourseInterface";
import React from "react";
import {Teacher} from "../../../interfaces/TeacherInterface";
import {Button, Image, Table} from "react-bootstrap";
import grade from "../../../assets/icons/grade_white.svg";

function TeacherNoteTable(props: { setCourse: (course: CourseWithMean) => void, promotion: number, teacher: Teacher }) {
    return (
        <Table striped hover className="rounded overflow-hidden my-2 text-center align-middle">
            <thead>
                <tr>
                    <th>Cours {props.promotion}</th>
                    <th>Moyenne / Ecart type</th>
                    <th>Edition</th>
                </tr>
            </thead>
            <tbody>
                {props.teacher.courses.filter(course => course.promotion === props.promotion)
                    .map((course, courseIndex)  => {
                        return (
                            <tr key={courseIndex}>
                                <td className="fw-bold">{course.courseName}</td>
                                <td>{course.mean ? course.mean + '/' + course.standardDeviation : ""}</td>
                                <td>
                                    <div>
                                        <Button variant="primary" className="m-2 p-0" onClick={() => props.setCourse(course)}><Image src={grade} fluid alt="Editer les notes" style={{ width: "30px" }} /></Button>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
        </Table>
    );
}

export default TeacherNoteTable;


import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Table from 'react-bootstrap/Table';
import { useAuth } from "react-oidc-context";
import supp from '../../../assets/icons/delete.svg';
import edit from '../../../assets/icons/edit.svg';
import { Teacher } from "../../../interfaces/TeacherInterface";
import { toast } from "react-toastify";



function TeacherTable(props: {
}) {
    const [teachers, setTeachers] = useState<Teacher[]>([]);
    const [editTeacherLogin, setEditTeacherLogin] = useState<string | null>(null);
    const [editedFirstName, setEditedFirstName] = useState<string>("");
    const [editedLastName, setEditedLastName] = useState<string>("");
    const [editedEmail, setEditedEmail] = useState<string>("");
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddProfModal, setShowAddProfModal] = useState(false);
    const [newFirstName, setNewFirstName] = useState<string>("");
    const [newLastName, setNewLastName] = useState<string>("");
    const [newEmail, setNewEmail] = useState<string>("");
    const [newLogin, setNewLogin] = useState<string>("");
    const [emailError, setEmailError] = useState<string | null>(null);
    const [loginError, setLoginError] = useState<string | null>(null);
    const [searchTerm, setSearchTerm] = useState<string>("");



    const auth = useAuth();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SCOLARITE_API_URL}/teacher`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => setTeachers(response.data), error => {
                toast.error("Une erreur est survenue");
            });
    }, [auth.user?.access_token]);


    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const filteredTeachers = teachers.filter((teacher) =>
        teacher.teacherLogin.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const validateEmail = (email: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateLogin = (login: string) => {
        return login === login.toLowerCase() && (login.includes('.') || login.includes('_'));
    };


    const handleEditTeacher = (teacherLogin: string) => {
        setEditTeacherLogin(teacherLogin);
        if (teachers) {
            const selectedTeacher = teachers.find(teacher => teacher.teacherLogin === teacherLogin);
            if (selectedTeacher) {
                setEditedLastName(selectedTeacher.lastName);
                setEditedFirstName(selectedTeacher.firstName);
                setEditedEmail(selectedTeacher.contactEmail);
                setShowEditModal(true);

            }
        }
    };

    const handleSaveEdit = () => {

        if (!emailRegex.test(editedEmail)) {
            setEmailError("Invalid email format");
            return;
        }

        if (editTeacherLogin?.toLowerCase() !== editTeacherLogin|| !(editTeacherLogin?.includes('.') || editTeacherLogin.includes('_') || editTeacherLogin === 'admin')) {
            setLoginError("Login must be in lowercase and contain a period (.)");
            return;
        }


        axios.patch(
            `${process.env.REACT_APP_SCOLARITE_API_URL}/teacher/${editTeacherLogin}`,
            {
                teacherLogin: editTeacherLogin,
                login: editTeacherLogin,
                firstName: editedFirstName,
                lastName: editedLastName,
                contactEmail: editedEmail,
            },
            {
                headers: { Authorization: `Bearer ${auth.user?.access_token}` },
            }
        )
            .then(() => {
                setTeachers((prevTeachers) => {
                    return prevTeachers.map((teacher) => {
                        if (teacher.teacherLogin === editTeacherLogin) {
                            return {
                                ...teacher,
                                firstName: editedFirstName,
                                lastName: editedLastName,
                                contactEmail: editedEmail.toLowerCase(),
                                teacherLogin: editTeacherLogin.toLowerCase(),
                            };
                        }
                        return teacher;
                    });
                });

                setShowEditModal(false);
            })
            .catch((error) => {
                toast.error("Une erreur est survenue");
                console.error("Error updating teacher:", error);
            });
    };
    const handleShowAddProfModal = () => {
        setShowAddProfModal(true);
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleAddTeacher = () => {

        setEmailError(null);
        setLoginError(null);

        if (!validateEmail(newEmail)) {
            setEmailError("Invalid email format");
            return;
        }

        if (!validateLogin(newLogin)) {
            setLoginError("Login must be in lowercase and contain a period (.)");
            return;
        }

        if (!newFirstName || !newLastName || !newEmail || !newLogin) {
            console.error("All fields must be filled");
            return;
        }
        const newTeacher = {
            lastName: newLastName,
            firstName: newFirstName,
            contactEmail: newEmail.toLowerCase(),
            teacherLogin: newLogin.toLowerCase(),
        };

        setEmailError(null);
        setLoginError(null);

        axios.post(`${process.env.REACT_APP_SCOLARITE_API_URL}/teacher`, newTeacher, {
            headers: { Authorization: `Bearer ${auth.user?.access_token}` },
        })
            .then((response) => {
                setTeachers((prevTeachers) => [...prevTeachers, response.data]);
                setTeachers((prevTeachers) =>
                    prevTeachers.sort((a, b) =>
                        a.lastName.localeCompare(b.lastName)
                    )
                );
                handleCloseAddProfModal();
            })
            .catch((error) => {
                toast.error("Une erreur est survenue");
                console.error("Error adding teacher:", error);
                if (error.message.includes("Invalid email format")) {
                    setEmailError(error.message);
                } else if (error.message.includes("Email must be in lowercase")) {
                    setLoginError(error.message);
                } else if (error.message.includes("Login must contain a period (.)")) {
                    setLoginError(error.message);
                }
            });

    };


    const handleCloseAddProfModal = () => {
        setShowAddProfModal(false);
    };


    if (!teachers)
        return (
            <Spinner animation="border" variant="primary" />
        );

    const handleDeleteTeacher = (teacherLogin: string) => {
        axios.delete(`${process.env.REACT_APP_SCOLARITE_API_URL}/teacher/${teacherLogin}`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => {
                setTeachers(teachers.filter(teacher => teacher.teacherLogin !== teacherLogin));
            })
            .catch(error => {
                toast.error("Une erreur est survenue");
                console.error("Erreur lors de la suppression du professeur :", error);
            });
    };

    return (
        <><Form className="mb-3" style={{ display: 'flex', alignItems: 'center' }}>
            <Form.Group controlId="formSearch" style={{ flex: 0.2, marginRight: '10px' }}>
                <Form.Control
                    type="text"
                    placeholder="Rechercher par login"
                    value={searchTerm}
                    onChange={handleSearch}
                />
            </Form.Group>
            <Button
                variant="primary"
                onClick={handleShowAddProfModal}
                style={{ backgroundColor: '#0000AA', padding: '10px',  marginLeft: 'auto' }}
            >
            Ajouter un professeur
            </Button>
        </Form>

        <Modal show={showAddProfModal} onHide={handleCloseAddProfModal}>
        </Modal><><Table borderless responsive className="rounded mt-2 overflow-hidden" striped>
            <thead className="text-center">
                <tr className="border-bottom">
                    <th className="fs-4">Nom</th>
                    <th className="fs-4">Prénom</th>
                    <th className="fs-4">Login</th>
                    <th className="fs-4">Email</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody className="text-center align-middle">
                {filteredTeachers.map((teacher, index) => (
                    <tr key={index}>
                        <td>{teacher.lastName}</td>
                        <td>{teacher.firstName}</td>
                        <td>{teacher.teacherLogin}</td>
                        <td>{teacher.contactEmail}</td>
                        <td></td>
                        <td>
                            <Button variant="secondary" onClick={() => handleEditTeacher(teacher.teacherLogin)}  className="rounded-8 m-1" >
                                <img src={edit} alt="Edit" style={{ width: '30px'}}  />
                            </Button>
                        </td>
                        <td>
                            <Button variant="danger" onClick={() => handleDeleteTeacher(teacher.teacherLogin)} className="rounded-8 m-1">
                                <img src={supp} alt="Delete" style={{ width: '30px', filter: 'invert(1)' }}  />
                            </Button>

                        </td>

                    </tr>
                ))}
            </tbody>
        </Table><Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Modifier le professeur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formLastName">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nouveau nom"
                            value={editedLastName}
                            onChange={(e) => setEditedLastName(e.target.value)}
                        />
                        <Form.Group controlId="formFirstName">
                            <Form.Label>Prénom</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nouveau prénom"
                                value={editedFirstName}
                                onChange={(e) => setEditedFirstName(e.target.value)}
                            />
                        </Form.Group>
                    </Form.Group>
                    <Form.Group controlId="formLogin">
                        <Form.Label>Login</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nouveau login"
                            value={editTeacherLogin || ''}
                            onChange={(e) => setEditTeacherLogin(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Nouvel email"
                            value={editedEmail || ''}
                            onChange={(e) => setEditedEmail(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Annuler
                </Button>
                <Button variant="primary" onClick={handleSaveEdit}>
            Enregistrer les modifications
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showAddProfModal} onHide={handleCloseAddProfModal}>
            <Modal.Header closeButton>
                <Modal.Title>Ajouter un professeur</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formNewLastName">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Entrez le nom"
                            value={newLastName}
                            onChange={(e) => setNewLastName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formNewFirstName">
                        <Form.Label>Prénom</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Entrez le prénom"
                            value={newFirstName}
                            onChange={(e) => setNewFirstName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formNewEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Entrez l'email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            isInvalid={!!loginError}
                        />
                        <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback>
                    </Form.Group>
                    {emailError && <div className="text-danger">{emailError}</div>}
                    <Form.Group controlId="formNewLogin">
                        <Form.Label>Login</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Entrez le login"
                            value={newLogin}
                            onChange={(e) => setNewLogin(e.target.value)}
                            isInvalid={!!loginError}
                        />
                        <Form.Control.Feedback type="invalid">
                            {loginError && <div className="text-danger">{loginError}</div>}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAddProfModal}>
                        Annuler
                </Button>
                <Button variant="primary" onClick={handleAddTeacher}>
                        Ajouter
                </Button>
            </Modal.Footer>
        </Modal></></>

    );
}


export default TeacherTable;

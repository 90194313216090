import React, { useState } from "react";
import axios from "axios";
import { Status, Absence } from "../../../interfaces/AbsenceInterface";
import { Button, Modal } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { toast } from "react-toastify";

function DetailsModal(props : {absenceModal: Absence | null, setAbsenceModal: (absence: Absence | null) => void}) {
    const auth = useAuth();
    const [isSubmitButtonDisable, SetSubmitButtonIsDisabled] = useState(false);
    
    const handleValidation = (isValid: boolean) => {
        if (props.absenceModal) {
            SetSubmitButtonIsDisabled(true);
            axios.post(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/justification/admin/update`,
                {
                    status: isValid,
                    absenceID: props.absenceModal.absenceId
                },
                { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
                .then(response => {
                    if (response.status === 200) {
                        props.setAbsenceModal(null);
                    }
                    SetSubmitButtonIsDisabled(false);
                }, error => {
                    toast.error("Une erreur est survenue");
                });
        } else {
            console.log("Validation failure");
        }
    };

    return (
        <Modal show={props.absenceModal !== null} onHide={() => props.setAbsenceModal(null)}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title>Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between my-2">
                    <span className="fw-bold">Cours : {props.absenceModal?.courseName}</span>
                    <span className="fw-bold">Date : {new Date(props.absenceModal?.courseDate ?? '').toLocaleDateString('FR-fr')}</span>
                </div>
                <span className="fw-bold">Description :</span>
                <textarea disabled className="w-100" defaultValue={props.absenceModal?.description} rows={6} />
                <span className="fw-bold">Pièce jointe : {props.absenceModal?.hasAttachement ? 'Oui' : 'Non'}</span>
            </Modal.Body>
            {props.absenceModal?.status === Status.EN_ATTENTE &&
            <Modal.Footer className="d-flex justify-content-between">
                <Button variant="success" className="me-1 d-flex"
                    onClick={() => handleValidation(true)} disabled={isSubmitButtonDisable}>Accepter la justification</Button>
                <Button variant="danger" className="ms-1 d-flex"
                    onClick={() => handleValidation(false)} disabled={isSubmitButtonDisable}>Refuser la justification</Button>
            </Modal.Footer>
            }
        </Modal>
    );
}

export default DetailsModal;


import axios from "axios";
import {AuthContextProps} from "react-oidc-context";
import { Course } from "../interfaces/CourseInterface";
import { toast } from "react-toastify";

export class CourseService {
    static async GetCourseById(auth: AuthContextProps, courseId: number): Promise<Course> {
        try {
            const response = await axios.get<Course>(`${process.env.REACT_APP_SCOLARITE_API_URL}/course/${courseId}`, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } });
            return response.data;
        } catch (error) {
            toast.error("Aucun cours trouvé avec cet identifiant.");
            throw error;
        }
    }

    static async GetCourseByName(auth: AuthContextProps, courseName: string, promotion: string): Promise<Course> {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SCOLARITE_API_URL}/course/${promotion}/${courseName}`,
                {
                    headers: {
                        Authorization: `Bearer ${auth.user?.access_token}`
                    }
                }
            );

            return response.data;
        } catch (error) {
            toast.error("Aucun cours trouvé avec ce nom.");
            throw error;
        }
    }

    static async GetCoursesByPromotion(auth: AuthContextProps, promotion: string): Promise<Course[]> {
        try {
            const response = await axios.get<Course[]>(`${process.env.REACT_APP_SCOLARITE_API_URL}/course/`, { 
                params: { promotion },
                headers: { Authorization: `Bearer ${auth.user?.access_token}` } 
            });
            return response.data;
        } catch (error) {
            toast.error("Aucun cours trouvé pour cette promotion.");
            throw error;
        }
    }
}

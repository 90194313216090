import axios from "axios";
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Spinner from "react-bootstrap/Spinner";
import Table from 'react-bootstrap/Table';
import { useAuth } from "react-oidc-context";
import { Declaration, Status } from "../../../interfaces/AbsenceInterface";
import { sortByDate, statusColor } from "../../../utils";
import { DetailsModal } from "./DeclarationModal";
import { toast } from "react-toastify";


function DeclarationTable(props: {
    setFiltereddeclarations: any; studentSelected: string, filtereddeclarations: Declaration[] | null | undefined
}) {
    const [declarations, setdeclarations] = useState<Declaration[] | null>(null);
    const [declarationModal, setDeclarationModal] = useState<Declaration | null>(null);
    const auth = useAuth();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_ABSENCE_API_URL}/v1/declaration/all`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => setdeclarations(sortByDate(response.data, "startDate")), error => {
                toast.error("Une erreur est survenue");
            });
    }, [auth.user?.access_token, declarationModal]);

    useEffect(() => {
        props.setFiltereddeclarations(declarations?.filter(declaration => props.studentSelected === "" || declaration.studentLogin === props.studentSelected));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declarations, props.studentSelected, declarationModal]);


    if (!declarations)
        return (
            <Spinner animation="border" variant="primary" />
        );
    return (
        <>
            <Table borderless responsive className="rounded mt-2 overflow-hidden">
                <thead className="text-center">
                    <tr className="border-bottom">
                        <th className="fs-4">Login</th>
                        <th className="fs-4">Date de début</th>
                        <th className="fs-4">Date de fin</th>
                        <th className="fs-4">Statut</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody className="text-center align-middle">
                    {props.filtereddeclarations?.map((declaration, index) => (
                        <tr key={index}>
                            <td>{declaration.studentLogin}</td>
                            <td>{new Date(declaration.startDate).toLocaleDateString('FR-fr')}</td>
                            <td>{new Date(declaration.endDate).toLocaleDateString('FR-fr')}</td>
                            <td className="align-items-center">
                                <span className={`d-block mx-auto w-50 p-2 rounded text-white ${statusColor(declaration.status)}`}>
                                    {declaration.status.toString()}
                                </span>
                            </td>
                            <td>
                                <Button variant={declaration.status === Status.EN_ATTENTE ? 'primary' : 'secondary'}
                                    onClick={() =>  setDeclarationModal(declaration)}>Détails</Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <DetailsModal setDeclarationModal={setDeclarationModal} declarationModal={declarationModal}/>
        </>
    );
}

export default DeclarationTable;

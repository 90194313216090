import { Course } from "../interfaces/CourseInterface";
import { CourseService } from "../services/CourseService";
import { AuthContextProps } from "react-oidc-context";

export class PromotionUtils {
    static getCurrentPromotion(): number {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth();
        const currentPromotion = currentYear + (currentMonth > 2 ? 1 : 0);
        
        return currentPromotion;
    }

    static async getPromotionCoursesNamesId(auth: AuthContextProps, promotion: number): Promise<Map<string, number>> {
        const response = await CourseService.GetCoursesByPromotion(auth, promotion.toString());

        const courses = new Map<string, number>();
        response.forEach((course: Course) => {
            courses.set(course.courseName, course.courseId);
        });

        return courses;
    }
}

export enum Status {
    'EXCUSE' = 'Excusé',
    'NON_JUSTIFIE' = 'Non justifié',
    'EN_ATTENTE' = 'En attente',
    'VU' = 'Vu'
}

export interface Absence {
    absenceId: string,
    studentLogin: string,
    courseName: string,
    courseDate: string,
    promotion: number,
    creationDate: string,
    status : Status,
    description : string,
    hasAttachement : boolean
    declarationId : number | null
}

export interface Declaration {
    declarationId : string;
    studentLogin : string;
    startDate : string;
    endDate : string;
    status : Status,
    description : string,
    hasAttachement : boolean
}

import axios from "axios";
import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { toast } from "react-toastify";

export default function AddModuleModal(props : { promotion: number, setAddModule : (b : boolean) => void, addModule : boolean } ) {
    const [name, setName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const auth = useAuth();

    const handleAddModule = (event : any) => {
        event.preventDefault();
        if (name === "" || description === "") {
            return;
        }
        axios.post(`${process.env.REACT_APP_SCOLARITE_API_URL}/module/`, {
            name: name,
            description: description,
            promotion: props.promotion,
        }, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(() => {
                props.setAddModule(false);
                window.location.reload();
            }, error => {
                toast.error("Une erreur est survenue");
            });
    };

    return (
        <Modal show={props.addModule} onHide={() => props.setAddModule(false)}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title>Ajouter un module</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleAddModule}>
                    <Form.Label>Nom :</Form.Label>
                    <Form.Control value={name} onChange={(e) => setName(e.target.value)}/>
                    <Form.Label>Description :</Form.Label>
                    <Form.Control as="textarea" rows={3} value={description} onChange={(e) => setDescription(e.target.value)}/>
                    <div className="d-flex justify-content-end m-2">
                        <Button type="submit">Valider</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { Teacher } from "../../../interfaces/TeacherInterface";
import Select from "react-select";
import axios from "axios";
import { Course } from "../../../interfaces/CourseInterface";
import { toast } from "react-toastify";

export default function AddCourseModal(props : { promotion: number, addCourseModuleId: number, setAddCourse: (b : number) => void, refresh: boolean, setRefresh: (refresh: boolean) => void, semester: number }) {
    const [name, setName] = useState<string>("");
    const [endDate, setEndDate] = useState<string>(new Date().toISOString().split('T')[0]);
    const [coefficient, setCoefficient] = useState<number>(0);
    const [teachers, setTeachers] = useState<Teacher[]>([]);
    const [courseteachers, setCourseTeachers] = useState<{value : string, label : string}[]>([]);
    const auth = useAuth();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SCOLARITE_API_URL}/teacher`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => setTeachers(response.data), error => {
                toast.error("Une erreur est survenue");
            });
    }, [auth.user?.access_token]);


    const handleAddCourse = async (event : any) => {
        event.preventDefault();

        if(name === "" || endDate === "" || coefficient === 0 || courseteachers.length === 0){
            return;
        }

        try {
            const date = new Date(endDate);
            const response = await axios.post<Course>(`${process.env.REACT_APP_SCOLARITE_API_URL}/course/`, {
                courseName: name,
                endDate: date.toISOString(),
                promotion : props.promotion,
                semester : props.semester,
                coefficient: coefficient,
            }, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } });

            await Promise.all(courseteachers.map(async (teacher: {value : string, label : string}) => {
                return axios.post(`${process.env.REACT_APP_SCOLARITE_API_URL}/teacher/addCourse/${teacher.value}`, {
                    courseId: response.data.courseId
                }, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } });
            }));

            await axios.put(`${process.env.REACT_APP_SCOLARITE_API_URL}/module/${props.addCourseModuleId}/addCourse`, {
                courseId: response.data.courseId
            }, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } });

            props.setAddCourse(0);
            props.setRefresh(!props.refresh);
        } catch (error) {
            toast.error("Une erreur est survenue");
            alert(error);
        }
    };

    return (
        <Modal show={props.addCourseModuleId !== 0} onHide={() => props.setAddCourse(0)}>
            <Modal.Header closeButton className="border-0">
                <Modal.Title>Ajouter un cours</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleAddCourse}>
                    <Form.Label>Nom :</Form.Label>
                    <Form.Control value={name} onChange={(e) => setName(e.target.value)}/>
                    <Form.Label>Date de fin :</Form.Label>
                    <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)}/>
                    <Form.Label>Coefficient :</Form.Label>
                    <Form.Control type="number" value={coefficient} onChange={(e) => setCoefficient(Number(e.target.value))}/>
                    <Form.Label>Professeurs :</Form.Label>
                    <Select
                        name="teachers"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        isMulti
                        defaultValue={courseteachers}
                        onChange={(selectedTeachers: any) => setCourseTeachers(selectedTeachers)}
                        options={teachers.map((teacher: Teacher) => ({ value: teacher.teacherLogin, label: teacher.firstName + " " + teacher.lastName }))}
                    />
                    <div className="d-flex justify-content-end m-2">
                        <Button type="submit">Valider</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

import React from "react";
import Annuaire from '../../../assets/icons/annuaire.svg';

function Header() {
    return (
        <div className="page-header d-flex align-items-center p-1 justify-content-between white-background rounded bg-light">
            <h1 className="position-absolute start-50 m-auto">Annuaire</h1>
            <img src={Annuaire} alt="Logo notes" className="ms-auto" style={{width : "50px"}} />
        </div>
    );
}

export default Header;

//React imports
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

//Other imports
import { User } from "oidc-client-ts";
import React, { useEffect, useState } from "react";
import { AuthProvider, hasAuthParams, useAuth } from "react-oidc-context";
import reportWebVitals from './reportWebVitals';

import './index.scss';
import "react-toastify/dist/ReactToastify.css";

//Pages imports
import Absences from "./pages/Absences";
import Dashboard from "./pages/Dashboard";
import Layout from "./pages/Layout";
import NoPage from "./pages/NoPage";
import Admin from "./pages/Teacher";
import Notes from "./pages/Notes";
import Annuaire from "./pages/Annuaire";
import Help from "./pages/Help";
import { ToastContainer } from "react-toastify";
import Syllabus from "./pages/Syllabus";

export default function App() {
    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    // automatically redirect to login page if we are not authenticated
    useEffect(() => {
        if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
      !hasTriedSignin
        ) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    // If we are not authenticated, we don't want to render anything
    if (!auth.isAuthenticated || auth.isLoading) {
        return <div></div>;
    }

    return (
        <div className="background">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Dashboard />} />
                        <Route path="notes" element={<Notes />} />
                        <Route path="absences" element={<Absences />} />
                        <Route path="absences/declaration" element={<Absences />} />
                        <Route path="annuaire" element={<Annuaire />} />
                        <Route path="admin" element={<Admin />} />
                        <Route path="help" element={<Help />} />
                        <Route path="syllabus" element={<Syllabus/>} />
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
            <ToastContainer theme="light"/>
        </div>
    );
}

// remove auth params from url
const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    );
};

const rootElement = document.getElementById('root');
const oidcConfig = {
    authority: String(process.env.REACT_APP_KEYCLOACK_AUTHORITY),
    client_id: String(process.env.REACT_APP_KEYCLOACK_CLIENT_ID),
    redirect_uri: String(process.env.REACT_APP_KEYCLOACK_REDIRECT_URI),
};
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
                <App />
            </AuthProvider>
        </React.StrictMode>
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

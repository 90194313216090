import React, { useState, ChangeEvent } from "react";
import { Form, Button } from "react-bootstrap";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { toast } from "react-toastify";

interface FormData {
    subject: string;
    description: string;
}
const ButtonState = {
    waiting : "Veuillez patienter...",
    submit : "Soumettre"
};
function HelpForm() {
    const auth = useAuth();
    const [submitButtonText, setSubmitButtonText] = useState(ButtonState.submit);
    const [formData, setFormData] = useState<FormData>({
        subject: "",
        description: "",
    });

    function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    }

    async function handleSendHelp() {
        try
        {
            if (formData.subject === "" || formData.description === "") {
                return;
            }
            setSubmitButtonText(ButtonState.waiting);
            const response = await axios.post(`${process.env.REACT_APP_SCOLARITE_API_URL}/help`, {...formData, name : auth.user?.profile.name}, {
                headers: {
                    Authorization: `Bearer ${auth.user?.access_token}`,
                }
            });
    
            if (response.status === 201) {
                toast.success("Votre demande a bien été envoyée !"); 
                setFormData({
                    subject: "",
                    description: "",
                });
                setSubmitButtonText(ButtonState.submit);
            } else {
                toast.error("Une erreur est survenue, veuillez réessayer plus tard.");
            }
        } catch (error)
        {
            toast.error("Une erreur est survenue");
            setSubmitButtonText(ButtonState.submit);
        }
        
    }

    return (
        <div className="d-flex rounded justify-content-center align-items-center bg-light p-2">
            <Form style={{ width: "80%" }}>
                <Form.Group controlId="formSubject" className="text-center">
                    <h4 className="p-2 fw-bold">Sujet de la demande : </h4>
                    <Form.Control
                        type="text"
                        placeholder="Sujet"
                        name="subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>

                <Form.Group controlId="formDescription" className="text-center mb-3">
                    <h4 className="p-2" style={{ fontSize: '1rem' }}>Description de la demande :</h4>
                    <Form.Control
                        as="textarea"
                        rows={6}
                        placeholder="Description"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        required
                    />
                </Form.Group>

                <Form.Group className="text-center">
                    <Button variant="primary" onClick={handleSendHelp} disabled={submitButtonText === ButtonState.waiting}>
                        {submitButtonText}
                    </Button>
                </Form.Group>
            </Form>
        </div>
    );
}

export default HelpForm;

import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import helpLogo from '../../../assets/icons/help.svg';


function HelpButtonModal(props: {content: string, isCard: boolean}) {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);


    return (
        <>
            <Button
                onClick={handleShowModal}
                className="d-flex align-items-center justify-content-center square-button"
                style={{
                    width : props.isCard ? '30px' :'5%',
                    height: props.isCard ? '30px' :'5%',
                    backgroundColor: 'white'
                }}>
                <img
                    src={helpLogo}
                    width='40'
                    height='40'
                    className='my-3'
                    alt='Aide'
                    title="Aide"
                    data-bs-tooltip="tooltip" 
                    data-bs-placement="right"
                    style={{ 
                        width : props.isCard ? '23px' :'40%',
                        height: props.isCard ? '23px' :'40%',
                        backgroundColor: 'white'
                    }}
                />
            </Button>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Description du composant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{props.content}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                    Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default HelpButtonModal;
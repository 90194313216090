import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { CourseWithMean } from '../../../../interfaces/CourseInterface';
import IndividualTable from './IndividualTable';
import GroupTable from './GroupTable';
import HelpButtonModal from '../../../student/help/helpModal';

enum Tab {
    INDIVIDUAL,
    GROUP
}

function EditTable(props: { course: CourseWithMean }) {
    const [tab, setTab] = useState<Tab>(Tab.INDIVIDUAL);
    const description = "Vous pouvez rentrer ici les notes. Vous avez un espace notes individuelles et un autre pour les groupes. Vous pouvez éditer le tableau en double cliquant sur une cellule note. Si cela est valide une icône ✅ s'affichera à droite !";

    return (
        <>
            <div className='bg-light d-flex flex-column rounded my-2 p-2'>
                <div className='d-flex m-2 align-middle'>
                    <Button variant="primary" onClick={() => window.location.reload()}>Retour</Button>
                    <h3 className='mx-2'>Entrée des notes du cours de {props.course.courseName} :</h3>
                </div>
                <div className='m-2'>
                    <Button
                        variant={tab === Tab.INDIVIDUAL ? 'primary' : 'secondary'}
                        onClick={() => setTab(Tab.INDIVIDUAL)}
                    >
                        Notes individuelles
                    </Button>
                    {!props.course.mean &&
                        <Button
                            variant={tab === Tab.GROUP ? 'primary' : 'secondary'}
                            className='m-2'
                            onClick={() => setTab(Tab.GROUP)}>
                            Notes de groupe
                        </Button>
                    }
                </div>
                {tab === Tab.INDIVIDUAL &&
                    <div className='m-2'>
                        <IndividualTable course={props.course} />
                    </div>
                }
                {tab === Tab.GROUP &&
                    <div className='m-2'>
                        <GroupTable course={props.course}/>
                    </div>
                }
                <div className='d-flex justify-content-end'>
                    <HelpButtonModal content={description} isCard={false}></HelpButtonModal>
                </div>
            </div>
        </>);
}

export default EditTable;

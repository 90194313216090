import React, {useContext, useState} from 'react';
import {SyllabusService} from "../../../../services/SyllabusService";
import {useAuth} from "react-oidc-context";
import { View, ViewContext } from '../../../../pages/Syllabus';
import { toast } from 'react-toastify';

function SyllabusAskModificationForm() {
    const auth = useAuth();
    const textLengthLimit = 500;
    const { selectedSyllabusCourse, currentPromo, setCurrentView, setSelectedSyllabusCourse } = useContext(ViewContext);
    const [formData, setFormData] = useState({
        modificationMessage: '',
    });

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            SyllabusService.SetEditState(auth, selectedSyllabusCourse.toString(), formData.modificationMessage, currentPromo)
                .then((response) => {
                    toast.success('Demande de modification de syllabus envoyée avec succès');
                    setSelectedSyllabusCourse(-1); // Unselect syllabus
                    setCurrentView(View.SyllabusTableView);
                });
        }
        catch (error) {
            toast.error('Erreur lors de la demande de modification de syllabus');
        }
    };

    return (
        <div className="h-100 w-100 d-flex flex-column my-2 p-2 justify-content-between white-background rounded bg-light align-items-center">
            <div className="d-flex flex-row justify-content-center align-items-center bg-primary rounded w-100 text-center text-white">
                Formulaire de demande de modification de syllabus
            </div>
            <br/>
            <form className="w-100" onSubmit={handleSubmit}>
                <div className="form-group mb-2">
                    <label htmlFor="modificationMessage">Message de la demande</label>
                    <textarea
                        className="form-control"
                        id="modificationMessage"
                        name="modificationMessage"
                        value={formData.modificationMessage}
                        onChange={handleChange}
                        style={{ height: '100px', resize: 'none' }}
                        maxLength={textLengthLimit}
                    />
                    <div style={{ color: textLengthLimit - formData.modificationMessage.length < 50 ? 'red' : 'gray', fontSize: '10px', marginTop: '4px' }}>
                        {textLengthLimit - formData.modificationMessage.length} / {textLengthLimit}
                    </div>
                </div>
                <br/>
                <button type="submit" className="btn btn-primary">
                    Soumettre
                </button>
            </form>
        </div>
    );
}

export default SyllabusAskModificationForm;

import React from "react";
import Absence from '../../../assets/icons/absence.svg';
import { PageTab } from "../../../pages/Absences";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function AbsenceHeader(props : {setTab : any, tab : string}) {

    const navigate = useNavigate();
    return (
        <div className="page-header d-flex align-items-center p-1 justify-content-between white-background rounded bg-light">
            <div className="btn-group" role="group" aria-label="Button group">
                <Button
                    variant={`${props.tab === PageTab.Absence ? 'primary' : 'secondary'}`}
                    className="rounded m-2 fw-bold"
                    onClick={() => {
                        props.setTab(PageTab.Absence);
                        navigate('/absences');
                    }}
                >
                    Liste d’absences
                </Button>
                <Button
                    variant={`${props.tab === PageTab.Declaration ? 'primary' : 'secondary'}`}
                    className="rounded m-2 fw-bold"
                    onClick={() => {
                        props.setTab(PageTab.Declaration);
                        navigate('/absences/declaration');
                    }}
                >
                    Déclarations
                </Button>
            </div>
            <h1 className="position-absolute start-50 m-auto">{props.tab}</h1>
            <img src={Absence} alt="Logo absence" className="p-1" style={{width : '50px'}}/>
        </div>);
}

export default AbsenceHeader;

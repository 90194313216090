import axios from "axios";
import {AuthContextProps} from "react-oidc-context";
import {Syllabus, SyllabusState} from "../interfaces/SyllabusInterface";
import { toast } from "react-toastify";
import {Course} from "../interfaces/CourseInterface";

export class SyllabusService {
    private static StatusSerializer(status: string): SyllabusState {
        switch (status) {
        case "filled":
            return SyllabusState.filled;
        case "validated":
            return SyllabusState.validated;
        case "toEdit":
            return SyllabusState.toEdit;
        default:
            return SyllabusState.toEdit;
        }
    }

    static async HealthStatus(auth: AuthContextProps) {
        axios.get(`${process.env.REACT_APP_SYLLABUS_API_URL}/ping`,
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .then(response => {
                console.log(response.data);
            }).catch(error => {
                console.error(error);
            });
    }

    /**
     * @description Create a new syllabus for a specific course
     *
     * @param {AuthContextProps} auth
     * @param {string} courseId
     * @param {string} promotion
     *
     * @returns {void}
     * @throws error if an error occurs when creating syllabus
     */
    static async CreateSyllabus(auth: AuthContextProps, course: Course, promotion?: string) {
        await axios.post(`${process.env.REACT_APP_SYLLABUS_API_URL}/syllabus`,
            { course, promotion, },
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .catch(error => {
                throw error;
            });
    }

    /**
     * @description Get all syllabuses for a specific promotion and teacher
     *
     * @param {AuthContextProps} auth
     * @param {string} promotion
     * @param {string} teacherLogin
     *
     * @returns {Syllabus[]} list of syllabuses or an empty array if no syllabus is found
     * @throws error if couldn't get syllabuses
     */
    static async GetSyllabuses(auth: AuthContextProps, promotion: string, teacherLogin?: string): Promise<Syllabus[]> {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SYLLABUS_API_URL}/syllabuses/${promotion}`,
                {headers: {Authorization: `Bearer ${auth.user?.access_token}`},
                    params: {
                        teacherLogin
                    }
                });

            let syllabuses: Syllabus[] = [];
            response.data.forEach((syllabus: any) => {
                syllabuses.push({
                    syllabusId: syllabus.syllabusId,
                    courseId: syllabus.courseId,
                    ueCoef: syllabus.ueCoef,
                    hoursMooc: syllabus.hoursMooc,
                    hoursCm: syllabus.hoursCm,
                    hoursCmTd: syllabus.hoursCmTd,
                    hoursTd: syllabus.hoursTd,
                    hoursCoaching: syllabus.hoursCoaching,
                    hoursExams: syllabus.hoursExams,
                    hoursPersonalWork: syllabus.hoursPersonalWork,
                    language: syllabus.language,
                    prerequisites: syllabus.prerequisites,
                    summary: syllabus.summary,
                    outcomes: syllabus.outcomes,
                    aavs: syllabus.aavs,
                    plan: syllabus.plan,
                    learningFormat: syllabus.learningFormat,
                    finalGradeCalcul: syllabus.finalGradeCalcul,
                    bibliography: syllabus.bibliography,
                    status: this.StatusSerializer(syllabus.status),
                    promotion: syllabus.promotion,
                    lastModificationDate: syllabus.lastModificationDate,
                    visibility: true
                });
            });

            return syllabuses;
        } catch (error) {
            toast.error("Aucun syllabus trouvé pour cette promotion.");
            throw error;
        }
    }

    /**
     * @description Update the status of a syllabus to editable state
     *
     * @param {AuthContextProps} auth
     * @param {string} courseId
     * @param {string} message
     * @param {string} promotion
     *
     * @returns {void}
     * @throws error if no syllabus is found
     */
    static async SetEditState(auth: AuthContextProps, courseId: string, message: string, promotion: string) {
        await axios.put(`${process.env.REACT_APP_SYLLABUS_API_URL}/syllabuses/editable`,
            { courseId, message, promotion },
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .catch(error => {
                throw error;
            });
    }

    /**
    * @description Update the status of a syllabus to validated state
    *
    * @param {AuthContextProps} auth
    * @param {string} courseId
    * @param {string} promotion
    *
    * @returns {void}
    * @throws error if no syllabus is found
    */
    static async SetValidatedState(auth: AuthContextProps, courseId: string, promotion: string) {
        await axios.put(`${process.env.REACT_APP_SYLLABUS_API_URL}/syllabuses/validated`,
            { courseId, promotion },
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .catch(error => {
                throw error;
            });
    }

    /**
     * @description Change the visibility of a syllabus
     *
     * @param {AuthContextProps} auth
     * @param {string} courseId
     * @param {string} promotion
     *
     * @returns {void}
     * @throws error if no syllabus is found
     */
    static async ChangeVisibility(auth: AuthContextProps, courseId: string, promotion: string) {
        await axios.put(`${process.env.REACT_APP_SYLLABUS_API_URL}/syllabuses/visibility`,
            { courseId, promotion },
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .catch(error => {
                throw error;
            });
    }

    /**
     * @description Update the informations of a syllabus
     *
     * @param {AuthContextProps} auth
     * @param {string} courseId
     * @param {number} hoursMooc
     * @param {number} hoursCm
     * @param {number} hoursCmTd
     * @param {number} hoursTd
     * @param {number} hoursCoaching
     * @param {number} hoursExams
     * @param {number} hoursPersonalWork
     * @param {string} language
     * @param {string} prerequisites
     * @param {string} summary
     * @param {string} outcomes
     * @param {string} plan
     * @param {string} aavs
     * @param {string} learningFormat
     * @param {string} finalGradeCalcul
     * @param {string} bibliography
     * @param {string} promotion
     *
     * @param message
     * @returns {void}
     * @throws error if no syllabus is found
     */
    static async UpdateSyllabusInfos(
        auth: AuthContextProps,
        courseId: string,
        hoursMooc: number,
        hoursCm: number,
        hoursCmTd: number,
        hoursTd: number,
        hoursCoaching: number,
        hoursExams: number,
        hoursPersonalWork: number,
        language: string,
        prerequisites: string,
        summary: string,
        outcomes: string,
        plan: string,
        aavs: string,
        learningFormat: string,
        finalGradeCalcul: string,
        bibliography: string,
        promotion: string,
        message?: string) {
        await axios.put(`${process.env.REACT_APP_SYLLABUS_API_URL}/syllabuses/edit`,
            {
                courseId,
                promotion,
                hoursMooc,
                hoursCm,
                hoursCmTd,
                hoursTd,
                hoursCoaching,
                hoursExams,
                hoursPersonalWork,
                language,
                prerequisites,
                summary,
                outcomes,
                plan,
                aavs,
                learningFormat,
                finalGradeCalcul,
                bibliography,
                message
            },
            { headers: { Authorization: `Bearer ${auth.user?.access_token}` } })
            .catch(error => {
                throw error;
            });
    }

    /**
    * @description Get the last syllabus for a specific course
    *
    * @param {AuthContextProps} auth
    * @param {string} courseId: id of the course
    * @param {string} promotion: promotion of the course (string)
    *
    * @returns last syllabus to date for a specific course
    * @throws error if no syllabus is found
    */
    static async GetLastSyllabusByCourseId(auth: AuthContextProps, courseId: string, promotion: string): Promise<Syllabus> {
        const response = await axios.get(`${process.env.REACT_APP_SYLLABUS_API_URL}/syllabuses/${courseId}/${promotion}`, { headers: { Authorization: `Bearer ${auth.user?.access_token}` } });

        let syllabus: Syllabus = {
            syllabusId: response.data.syllabusId,
            courseId: response.data.courseId,
            ueCoef: response.data.ueCoef,
            hoursMooc: response.data.hoursMooc,
            hoursCm: response.data.hoursCm,
            hoursCmTd: response.data.hoursCmTd,
            hoursTd: response.data.hoursTd,
            hoursCoaching: response.data.hoursCoaching,
            hoursExams: response.data.hoursExams,
            hoursPersonalWork: response.data.hoursPersonalWork,
            language: response.data.language,
            prerequisites: response.data.prerequisites,
            summary: response.data.summary,
            outcomes: response.data.outcomes,
            aavs: response.data.aavs,
            plan: response.data.plan,
            learningFormat: response.data.learningFormat,
            finalGradeCalcul: response.data.finalGradeCalcul,
            bibliography: response.data.bibliography,
            status: this.StatusSerializer(response.data.status),
            promotion: response.data.promotion,
            lastModificationDate: response.data.lastModificationDate,
            visibility: response.data.visibility
        };

        return syllabus;
    }

    static async ExportAllSyllabuses(auth: AuthContextProps, promotion: string) {
        const url = `${process.env.REACT_APP_SYLLABUS_API_URL}/syllabuses/exports/${promotion}`;
        console.log(url);

        try {
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${auth.user?.access_token}` },
                responseType: 'blob'
            });

            const blob = new Blob([response.data], { type: 'application/zip' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = `Syllabuses-${promotion}.zip`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error("Erreur lors de l'exportation des syllabus.", error);
            toast.error("Erreur lors de l'exportation des syllabus.");
            throw error;
        }
    }
    static async ExportSyllabus(auth: AuthContextProps, courseId: string, promotion: string, courseName: string) {
        const url = `${process.env.REACT_APP_SYLLABUS_API_URL}/syllabuses/export/${promotion}/${courseId}`;
        try {
            const response = await axios.get(url, {
                headers: { Authorization: `Bearer ${auth.user?.access_token}` },
                responseType: 'blob'
            });

            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = `Syllabus-${courseName}-${promotion}.docx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            toast.error("Erreur lors de l'exportation d'un syllabus.");
            throw error;
        }
    }
}
